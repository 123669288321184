import { SYSTEM_ERROR } from "config/CONSTANTS";
import { axiosGet, axiosPost } from "./ajaxServices";
import { urlGetAdminVerificationDetail, urlAdminCompleteVerification, urlAdminCancelVerification} from "./CONSTANTS";

export const apiGetAdminVerificationDetail = () => {
  const url = urlGetAdminVerificationDetail;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, SYSTEM_ERROR)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
};

export const apiAdminCompleteVerification = (params) => {
  const url = urlAdminCompleteVerification;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("secret", params.secret); 
  formData.append("otp", params.otp); 
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
};

export const apiAdminCancelVerification = (params) => {
  const url = urlAdminCancelVerification;
  return new Promise((resolve, reject) => {
    axiosGet(url, params, SYSTEM_ERROR)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
};

