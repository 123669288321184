import AdminLayout from "layouts/AdminLayout/AdminLayout";
import { ROUTE_ADMIN_USERS } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import {
  apiAdminGetUserDetail,
  apiAdminUpdateUserInfo,
  apiAdminUpdateUserStatus,
} from "services/adminUsersService";
import { empty, isEmpty, showToast, show_loading } from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import { InputTags } from "react-bootstrap-tagsinput";
// import "react-bootstrap-tagsinput/dist/index.css";
 
import { useHistory } from "react-router-dom";
import AdminUserPhotoBox from "./inc/AdminUserPhotoBox";

const AdminUserDetailPage = (props) => {
  const userid = props.match.params.userid;
  const currentPageTitle = "User Detail #" + userid;

  const userDataStore = useSelector((x) => x.userDataStore);
  const history = useHistory();

  const dispatch = useDispatch();
  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_ADMIN_USERS,
        m_user_menu_collapsed: true,
      })
    );
  };
  useEffect(() => {
    initPage();
    getData();
  }, [userid]);

  const setPgageData = (user_info) => {
    setUserData({ ...user_info });
    setTags(user_info["keyword_arr"]);

    let user_image_list = [];
    for (let k in user_info["user_photos"]) {
      let row = user_info["user_photos"][k];
      user_image_list.push(row["original"]["url"]);
    }
    setImages([...user_image_list]);
    console_log("user_info", user_info);
  };

  const getData = () => {
    let params = {
      user_id: userid,
    };
    show_loading(true);
    apiAdminGetUserDetail(params)
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          let user_info = api_res["data"]["user"];
          setPgageData(user_info);
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };

  const initUserData = {
    keyword_arr: [],
    user_photos: [],
  };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(userData, "name", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let updateData = { ...userData };
    updateData[field_name] = e.target.value;
    setUserData({ ...updateData });
  };

  const submitData = () => {
    let is_valid = validateFields();
    //console_log("userData:::", userData)
    if (is_valid) {
      show_loading(true);
      apiAdminUpdateUserInfo({ ...userData, keyword_arr: tags })
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          if (api_res.status == 200) {
            let user_info = api_res["data"]["user"];
            setPgageData(user_info);
            showToast(api_res.message, "success");
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  };

  const updateUserStatus = (user_status) => {
    if (user_status) {
      show_loading(true);
      apiAdminUpdateUserStatus({ id: userData["id"], user_status: user_status })
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          if (api_res.status == 200) {
            let user_info = api_res["data"]["user"];
            setPgageData(user_info);
            showToast(api_res.message, "success");
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  };

  const [tags, setTags] = useState([]);
  const onChangeTags = (t) => {
    console_log("t::::", t)
    setTags(t.values);
  };

  const [images, setImages] = useState([]); 

  return (
    <>
      <AdminLayout>
        <div className="admin-user-detail-page">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title t-show-mobile">
                    {currentPageTitle}
                  </h4>
                  <div className="card-content">
                    <div className="row">
                      <div className="col-md-12">
                        <AdminUserPhotoBox isVisible={true} photoList={images} boxClass={` `} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label className="form-label" htmlFor="name">
                            Name
                          </label>
                          <input
                            type="text"
                            className={`form-control name ${
                              errorField.includes("name") ? "is-invalid" : ""
                            }`}
                            id="name"
                            name="name"
                            value={
                              !empty(userData["name"]) ? userData["name"] : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "name");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label className="form-label" htmlFor="email">
                            Email Address
                          </label>
                          <input
                            type="text"
                            className={`form-control email ${
                              errorField.includes("email") ? "is-invalid" : ""
                            }`}
                            id="email"
                            name="email"
                            value={
                              !empty(userData["email"]) ? userData["email"] : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "email");
                            }}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label className="form-label" htmlFor="phone_number">
                            Phone number
                          </label>
                          <input
                            type="text"
                            className={`form-control phone ${
                              errorField.includes("phone_number") ? "is-invalid" : ""
                            }`}
                            id="phone_number"
                            name="phone_number"
                            value={
                              !empty(userData["phone_number"]) ? userData["phone_number"] : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "phone_number");
                            }}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label
                            className="form-label"
                            htmlFor="status_message"
                          >
                            Status message
                          </label>
                          <input
                            type="text"
                            className={`form-control status_message ${
                              errorField.includes("status_message")
                                ? "is-invalid"
                                : ""
                            }`}
                            id="status_message"
                            name="status_message"
                            value={
                              !empty(userData["status_message"])
                                ? userData["status_message"]
                                : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "status_message");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-md-12 col-12">
                        <div className="mb-1">
                          <label
                            className="form-label"
                            htmlFor="user_keyword_list"
                          >
                            Keywords
                          </label>
                          <div className="user_keyword_list tag-box">
                            <InputTags                           
                              values={tags}
                              onTags={(value) => onChangeTags(value)}                             
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-footer">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mt-2">
                            <div className="button-group text-center">
                              <button
                                type="button"
                                className="mb-50 btn btn-lg1 btn-primary me-50"
                                onClick={(e) => {
                                  submitData();
                                }}
                              >
                                Update
                              </button>
                              {(userData["deactivation_ends_at"] == "" ||
                                userData["deactivation_ends_at"] == null) && (
                                <button
                                  type="button"
                                  className="mb-50 btn btn-lg1 btn-warning me-50"
                                  onClick={(e) => {
                                    updateUserStatus("deactivate");
                                  }}
                                >
                                  Suspend Account
                                </button>
                              )}
                              {!empty(userData["deactivation_ends_at"]) && (
                                <button
                                  type="button"
                                  className="mb-50 btn btn-lg1 btn-info me-50"
                                  onClick={(e) => {
                                    updateUserStatus("activate");
                                  }}
                                >
                                  Activate Account
                                </button>
                              )}

                              <button
                                type="button"
                                className="mb-50 btn btn-lg1 btn-light"
                                onClick={(e) => {
                                  history.push(ROUTE_ADMIN_USERS);
                                }}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default AdminUserDetailPage;
