import AdminLayout from "layouts/AdminLayout/AdminLayout";
import {
  ROUTE_ADMIN_COMPLIMENT_MESSAGES,
  ROUTE_ADMIN_PROFILE,
  ROUTE_ADMIN_USERS,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import {
  apiAdminGetProfileInfo,
  apiAdminUpdateProfile,
} from "services/adminCommonService";
import {
  apiAdminGetUserDetail,
  apiAdminUpdateUserInfo,
  apiAdminUpdateUserStatus,
} from "services/adminUsersService";
import { empty, isEmpty, showToast, show_loading } from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import { InputTags } from "react-bootstrap-tagsinput";
// import "react-bootstrap-tagsinput/dist/index.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { useHistory } from "react-router-dom";
import {
  apiAdminDeleteComplimentMessage,
  apiAdminGetComplimentMessageList,
  apiAdminUpdateComplimentMessage,
} from "services/adminComplimentsService";
import AdminComplimentMessageModal from "./inc/AdminComplimentMessageModal";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

const AdminComplimentMessagePage = (props) => {
  const currentPageTitle = "Compliments Messages"

  const userDataStore = useSelector((x) => x.userDataStore);
  const history = useHistory();

  const dispatch = useDispatch();
  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_ADMIN_COMPLIMENT_MESSAGES,
        m_user_menu_collapsed: true,
      })
    );
  };
  useEffect(() => {
    initPage();
    getData();
  }, []);

  const [pageData, setPageData] = useState([]);

  const getData = () => {
    show_loading(true);
    let params = {};
    show_loading(true);
    apiAdminGetComplimentMessageList(params)
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          setPageData(api_res["data"]);
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };

  const defaultItem = {
    id: 0,
    message: "",
  };
  const [currentItem, setCurrentItem] = useState(defaultItem);
  const [showItemInfoModal, setShowItemInfoModal] = useState(false);

  const onClickAddItem = () => {
    setCurrentItem(defaultItem);
    setShowItemInfoModal(true);
  };
  const onClickEditItem = (item) => {
    setCurrentItem(item);
    setShowItemInfoModal(true);
  };
  const onClickDeleteItem = (item) => {
    setCurrentItem(item);
    setConfirmModalTitle("Are you sure you want to delete?");
    setConfirmedAction("delete_item");
    setShowConfirmModal(true);
  };

  const submitData = (item) => {
    console.log("item:::", item);
    show_loading(true);
    apiAdminUpdateComplimentMessage(item)
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          setPageData(api_res["data"]);
        }
        showToast(api_res.message, "success");
        setShowItemInfoModal(false);
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };

  const [confirmModalTitle, setConfirmModalTitle] = useState(
    "Are you sure you want to delete?"
  );
  const [confirmedAction, setConfirmedAction] = useState("delete_item");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const doConfirmedAction = () => {
    let params = {
      id: currentItem["id"],
    };
    if (confirmedAction === "delete_item") {
      show_loading(true);
      apiAdminDeleteComplimentMessage(params)
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          setPageData(api_res["data"]);
        }
        showToast(api_res.message, "success");
        setShowConfirmModal(false);
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
    }
  };
  return (
    <>
      <AdminLayout>
        <div className="admin-compliments-page">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title t-show-mobile">
                    {currentPageTitle}
                  </h4>
                  <div className="card-content">
                    <div className="form-header">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-2">
                            <div className="button-group text-left">
                              <button
                                type="button"
                                className="mb-50 btn btn-lg1 btn-primary me-50"
                                onClick={(e) => {
                                  onClickAddItem();
                                }}
                              >
                                Add Custom Message
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Message {`(${pageData.length})`}</th>
                                <th style={{ width: "140px" }}>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pageData.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item["message"]}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-icon td-btn btn-info"
                                        title="Detail"
                                        onClick={(e) => {
                                          onClickEditItem(item);
                                        }}
                                      >
                                        <i className="las la-pencil-alt"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-icon td-btn btn-danger"
                                        title="Delete"
                                        onClick={(e) => {
                                          onClickDeleteItem(item);
                                        }}
                                      >
                                        <i className="las la-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AdminComplimentMessageModal
            modalTitle={`${
              currentItem["id"] === 0 ? "Add" : "Edit"
            } Compliment`}
            isVisible={showItemInfoModal}
            setVisibleModal={setShowItemInfoModal}
            pageData={pageData}
            currentRow={currentItem}
            submitModalData={submitData}
            modalClass="user-page admin-page compliment-info-modal"
          />

          <ConfirmModal
            content={confirmModalTitle}
            onClickYes={doConfirmedAction}
            visibleModal={showConfirmModal}
            setVisibleModal={setShowConfirmModal}
            modalClass="user-page admin-page confirm-modal"
          />
        </div>
      </AdminLayout>
    </>
  );
};

export default AdminComplimentMessagePage;
