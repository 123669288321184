import AdminLayout from "layouts/AdminLayout/AdminLayout";
import { ROUTE_ADMIN_REPORTS, ROUTE_ADMIN_USERS } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import { apiAdminGetProfileInfo } from "services/adminCommonService";
import { apiGetAdminReportsPageDetail } from "services/adminReportsService";
import { showToast, show_loading } from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import AdminReportListTable from "./inc/AdminReportListTable";

const currentPageTitle = "Reports";
const AdminReportsPage = () => {
  const dispatch = useDispatch();
  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_ADMIN_REPORTS,
        m_user_menu_collapsed: true,
      })
    );
  };
  useEffect(() => {
    initPage();
    getData();
  }, []);
  const history = useHistory();
  const defaultPageData = {};
  const [pageData, setPageData] = useState(defaultPageData);
  const getData = () => {
    show_loading(true);
    apiGetAdminReportsPageDetail()
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          let user_info = api_res["data"]["user"];
          dispatch(updateUser(user_info));
          setPageData(api_res["data"]);
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };

  return (
    <>
      <AdminLayout>
        <div className="user-list-page">
          <div className={`row`}>
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title t-show-mobile">
                    {currentPageTitle}
                  </h4>
                  <div className="card-content">
                    <div className="datatable-page">
                      <AdminReportListTable pageData={pageData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default AdminReportsPage;
