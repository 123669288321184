import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  empty,
  get_data_value,
  intval,
  isEmpty,
  jQuery,
  showToast,
} from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";
import { console_log } from "utils/logger";

function AdminUserDownloadModal(props) {
  const {
    isVisible,
    setVisibleModal,
    pageData,
    submitModalData,
    modalClass,
    modalTitle,
  } = props;
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      setModalPageData(pageData);
      initColumnCheckbox()
    }
  }, [isVisible, pageData]);
  const [show, setShow] = useState(false);
  const defaultModalData = {
    user_name: "",
    user_email: "",
    user_password: "",
    balance: "",
  };
  const [modalPageData, setModalPageData] = useState({});
  const [modalData, setModalData] = useState(defaultModalData);
  const [errorField, setErrorField] = useState([]);
  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  const [pageRange, setPageRange] = useState("all");
  const pageRangeList = [
    { label: "All", value: "all" },
    { label: "Current Page", value: "current_page" },
  ];
  const onChangePageRange = (a) => {
    setPageRange(a);
  };
 
  const [pageColumnList, setColumnList] = useState([]);
  const columnList = [
    // { label: "All", value: "all" },
    { label: "Name", value: "name", checked: true },
    { label: "Email", value: "email", checked: true },
    { label: "Number", value: "phone", checked: true },
    { label: "Age", value: "age", checked: true },
    { label: "Gender", value: "gender_id", checked: true },
    { label: "Location", value: "location", checked: true },
    { label: "Added Date", value: "created_at", checked: true },
  ];
  const initColumnCheckbox = ()=>{
    let default_column_list = []
    for(let k in columnList) {
      let row = columnList[k]
      if(row['checked']) {
        default_column_list.push(row['value'])
      }
    }
    setColumnList([...default_column_list])
  }
  
  const onChangePageColumn = (a, e) => {
    if (e.target.checked) {
      if (!pageColumnList.includes(a)) {
        setColumnList([...pageColumnList, a]);
      }
    } else {
      let page_column_list = pageColumnList;
      let index = pageColumnList.indexOf(a);
      if (index !== -1) {
        page_column_list.splice(index, 1);
        setColumnList([...page_column_list])
      }
    }
  };

  const onClickSubmitModal = () => {
    if(pageColumnList.length == 0) {
      showToast("No column selected", "error")
      return false
    }
    let downloadOption = {
      pageRange:pageRange,
      pageColumnList: pageColumnList
    }
    submitModalData(downloadOption);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-center">
            <div className="modal-box">
              <div id="dialog">
                <div className="text-normal text-left">
                  <div className="text-left">
                    <div className="form-group row mt-1">
                      <div className="col-sm-12">
                        <div className="form-row-box">
                          <div className="form-row-label">
                            <label>Page</label>
                          </div>
                          <div className="form-row-content">
                            {pageRangeList.map((item, index) => (
                              <div
                                className="form-check form-check-inline form-check-primary"
                                key={index}
                              >
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="page_range"
                                    id={`page_range_${item["value"]}`}
                                    value={item["value"]}
                                    checked={pageRange === item["value"]}
                                    onChange={(e) => {
                                      onChangePageRange(item["value"]);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`page_range_${item["value"]}`}
                                  >
                                    {item["label"]}
                                  </label>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="form-row-box">
                          <div className="form-row-label">
                            <label>Column</label>
                          </div>
                          <div className="form-row-content">
                            {columnList.map((item, index) => (
                              <div
                                className="form-check form-check-inline form-check-primary"
                                key={index}
                              >
                                <label className="form-check-label">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="page_range"
                                    id={`page_range_${item["value"]}`}
                                    value={item["value"]}
                                    checked={pageColumnList.includes(
                                      item["value"]
                                    )}
                                    onChange={(e) => {
                                      onChangePageColumn(item["value"], e);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`page_range_${item["value"]}`}
                                  >
                                    {item["label"]}
                                  </label>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light margin-right-10"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-md btn-submit-modal-data btn-primary"
            onClick={onClickSubmitModal}
          >
            Download
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminUserDownloadModal;
