import { axiosGet, axiosPost, axiosPostMultipart } from "./ajaxServices";
import { urlAdminCheckCurrentPassword, urlAdminGetProfile, urlAdminSetUserTmpPassword, urlAdminUpdatePassword, urlAdminUpdateProfile, urlUserCheckHasActiveLicense, urlUserCheckHlxPassword, urlUserGetProfileInfo } from "./CONSTANTS";

export const apiAdminGetProfileInfo = () => {
  const url = urlAdminGetProfile;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiAdminUpdateProfile = (params) => {
  const url = urlAdminUpdateProfile;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("first_name", params.first_name);
  formData.append("last_name", params.last_name);
  formData.append("email", params.email);
  formData.append("phone_number", params.phone_number);
  formData.append("new_password", params.new_password);
  formData.append("c_new_password", params.c_new_password);
  return new Promise((resolve, reject) => {
    axiosPostMultipart(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiAdminUpdatePassword = (params) => {
  const url = urlAdminUpdatePassword;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("new_password", params.new_password);
  formData.append("c_new_password", params.c_new_password);
  return new Promise((resolve, reject) => {
    axiosPostMultipart(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiAdminCheckCurrentPassword = (params) => {
  const url = urlAdminCheckCurrentPassword;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("password", params.password);
  return new Promise((resolve, reject) => {
    axiosPostMultipart(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};