import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  empty,
  get_data_value,
  intval,
  isEmpty,
  jQuery,
  showToast,
  show_loading,
} from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";

import { InputTags } from "react-bootstrap-tagsinput";
// import "react-bootstrap-tagsinput/dist/index.css";

import AdminUserPhotoBox from "./AdminUserPhotoBox";
import { apiAdminDeleteUser, apiAdminUpdateUserStatus } from "services/adminUsersService";
import { console_log } from "utils/logger";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import AdminBanReasonModal from "./AdminBanReasonModal";
import AdminMessageModal from "./AdminMessageModal";

function AdminUserInfoModal(props) {
  const {
    isVisible,
    setVisibleModal,
    pageData,
    currentRow,
    submitModalData,
    modalClass,
    modalTitle,
    onReloadTable,
    reloadReportTable
  } = props;
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      setModalPageData(pageData);
      if (!empty(currentRow)) {
        setModalData({ ...defaultModalData, ...currentRow });

        let user_image_list = [];
        for (let k in currentRow["user_photos"]) {
          let row = currentRow["user_photos"][k];
          user_image_list.push(row["original"]["url"]);
        }
        setImages([...user_image_list]);
      }
    }
  }, [isVisible, pageData, currentRow]);
  const [show, setShow] = useState(false);
  const defaultModalData = {
    name: "",
  };
  const [modalPageData, setModalPageData] = useState({});
  const [modalData, setModalData] = useState(defaultModalData);
  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(modalData, "name", errorList);
    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let updateData = {};
    updateData[field_name] = e.target.value;
    setModalData({ ...modalData, ...updateData });
  };
  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  const onClickSubmitModal = () => {
    let is_valid = validateFields();
    if (is_valid) {
      submitModalData({ ...modalData, id: currentRow["id"] });
    }
  };

  const updateUserStatus = (user_status) => {
    if (user_status) {
      show_loading(true);
      apiAdminUpdateUserStatus({ id: modalData["id"], user_status: user_status })
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          if (api_res.status == 200) {
            let user_info = api_res["data"]["user"];
            setModalData(user_info);
            showToast(api_res.message, "success");
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  };

  const onClickDeleteUser = () => {
    setConfirmModalTitle("Are you sure you want to delete?");
    setConfirmedAction("delete_user");
    setShowConfirmModal(true);
  };

  const [showAdminMessageModal, setShowAdminMessageModal] = useState(false);
  const onClickMessageUser = () => {
    setShowAdminMessageModal(true);
  };

  const [confirmModalTitle, setConfirmModalTitle] = useState(
    "Are you sure you want to delete?"
  );
  const [confirmedAction, setConfirmedAction] = useState("change_user_status");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const doConfirmedAction = () => {
    let params = {};
    if (confirmedAction === "delete_user") {
      params = {
        user_id: currentRow["id"],
      };
      show_loading(true);
      apiAdminDeleteUser(params)
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          setShowConfirmModal(false);
          if (api_res.status == 200) {
            showToast(api_res["message"], "success");
          }
          onReloadTable("AdminUserInfoModal")
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  };

  const [showUserBanModal, setShowUserBanModal] = useState(false);

  const [tags, setTags] = useState([]);
  const onChangeTags = (t) => {
    //console_log("t::::", t)
    setTags(t.values);
  };

  const [images, setImages] = useState([]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-center">
            <div className="modal-box">
              <div id="dialog">
                <div className="text-normal text-left">
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-12 vhalign">
                      <div style={{ maxWidth: "200px", margin: "auto" }}>
                        <AdminUserPhotoBox
                          isVisible={true}
                          photoList={images}
                          boxClass={`simple-carousel`}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-12">
                      <div className="user-personal-info-box">
                        <div className="box-title">
                          <h4 className="fw-bolder border-bottom pb-50 mb-1">
                            Personal Information
                          </h4>
                        </div>
                        <ul className="list-unstyled">
                          <li className="mb-75">
                            <span className="fw-bolder me-25">Name:</span>
                            <span>{modalData["name"]}</span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">Email:</span>
                            <span>{modalData["email"]}</span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">Mobile:</span>
                            <span>
                              {modalData["phone_number"]
                                ? modalData["phone_number"]
                                : ""}
                            </span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">Birth date:</span>
                            <span>{modalData["birthdate"]}</span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">Gender:</span>
                            <span>
                              {modalData["gender"]
                                ? modalData["gender"]["name"]
                                : ""}
                            </span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">Location:</span>
                            <span>{modalData["location"]}</span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">
                              Looking for:
                            </span>
                            <span>
                              {modalData["looking_for_genders"] &&
                                modalData["looking_for_genders"].map(
                                  (item, index) => {
                                    return (
                                      <span
                                        className="badge bg-light-secondary me-25 ms-25 mb-25"
                                        key={index}
                                      >
                                        {item["name"]}
                                      </span>
                                    );
                                  }
                                )}
                            </span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">Language:</span>
                            <span>
                              {modalData["languages"] &&
                                modalData["languages"].map((item, index) => {
                                  return (
                                    <span
                                      className="badge bg-light-secondary me-25 ms-25 mb-25"
                                      key={index}
                                    >
                                      {item["name"]}
                                    </span>
                                  );
                                })}
                            </span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">Keywords:</span>
                            <span>
                              {modalData["keyword_arr"] &&
                                modalData["keyword_arr"].map((item, index) => {
                                  return (
                                    <span
                                      className="badge bg-light-secondary me-25 ms-25 mb-25"
                                      key={index}
                                    >
                                      {item}
                                    </span>
                                  );
                                })}
                            </span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">Status message:</span>
                            <span>{modalData["status_message"]}</span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">
                              Registration date:
                            </span>
                            <span>
                              {modalData["created_at"]
                                ? modalData["created_at"].substr(0, 10)
                                : ""}
                            </span>
                          </li>
                          <li className="mb-75">
                            <span className="fw-bolder me-25">
                              Account status:
                            </span>
                            {empty(modalData["deactivation_ends_at"]) && (
                              <span className="badge bg-light-success me-25 ms-25">
                                Active
                              </span>
                            )}
                            {!empty(modalData["deactivation_ends_at"]) && (
                              <span className="badge bg-light-warning me-25 ms-25">
                                Banned
                              </span>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-12 col-md-12 col-12">
                      <div className="user-subscription-info-box">
                        <div className="box-title">
                          <h4 className="fw-bolder border-bottom pb-50 mb-1">
                            Subscriptions
                          </h4>
                        </div>
                        <div className="table-responsive" style={{ maxHeight: "280px", overflow: "auto" }}>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Plan</th>
                                <th>Subscription type</th>
                                <th>Starts at</th>
                                <th>Ends at</th>
                              </tr>
                            </thead>
                            <tbody>
                              {modalData["subscription_list"] &&
                                modalData["subscription_list"].length > 0 ? (
                                modalData["subscription_list"].map(
                                  (item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td className="">
                                          {item["plan_name"]}
                                        </td>
                                        <td className="">{item["slug"]}</td>
                                        <td className="">
                                          {item["starts_at"].substr(0, 10)}
                                        </td>
                                        <td className="">
                                          {item["ends_at"] &&
                                            item["ends_at"].substr(0, 10)}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td colSpan={4} className="text-center">
                                    No subscription
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {(modalData["deactivation_ends_at"] == "" ||
            modalData["deactivation_ends_at"] == null) && (
              <button
                type="button"
                className="btn btn-lg1 btn-warning"
                onClick={(e) => {
                  setShowUserBanModal(true);
                }}
                style={{ minWidth: "90px" }}
              >
                Ban
              </button>
            )}
          {!empty(modalData["deactivation_ends_at"]) && (
            <button
              type="button"
              className="btn btn-lg1 btn-info"
              onClick={(e) => {
                updateUserStatus("activate");
              }}
              style={{ minWidth: "90px" }}
            >
              Unban
            </button>
          )}

          <button
            type="button"
            className="btn btn-lg1 btn-danger"
            title="Delete"
            onClick={() => onClickDeleteUser()}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-lg1 btn-info"
            title="Message"
            onClick={() => onClickMessageUser()}
          >
            Message
          </button>
          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <ConfirmModal
        content={confirmModalTitle}
        onClickYes={doConfirmedAction}
        visibleModal={showConfirmModal}
        setVisibleModal={setShowConfirmModal}
        modalClass="user-page admin-page confirm-modal"
      />

      <AdminBanReasonModal
        modalTitle={`Ban User`}
        isVisible={showUserBanModal}
        setVisibleModal={setShowUserBanModal}
        pageData={pageData}
        currentRow={currentRow}
        submitModalData={(row) => {
          setModalData({ ...modalData, ...row });
          onReloadTable("AdminUserInfoModal")
        }}
        modalClass="user-page admin-page user-ban-modal"
      />

      <AdminMessageModal
        modalTitle={`Send a message`}
        isVisible={showAdminMessageModal}
        setVisibleModal={setShowAdminMessageModal}
        pageData={pageData}
        currentRow={currentRow}
        submitModalData={() => {
          setShowAdminMessageModal(false)
        }}
        modalClass="user-page admin-page user-ban-modal"
        reloadReportTable={reloadReportTable}
      />
    </>
  );
}

export default AdminUserInfoModal;
