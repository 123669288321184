import { APP_NAME, SYSTEM_ERROR } from "config/CONSTANTS";
import { curl_post } from "utils/GlobalFunctions";
import { axiosGet, axiosPost } from "./ajaxServices";
import {urlAdminDeleteReport, urlAdminDeleteUser, urlAdminGetReportsPageDetail, urlAdminGetUserDetail, urlAdminGetUsersPageDetail, urlAdminUpdateReportStatus, urlAdminUpdateUserInfo, urlAdminUpdateUserStatus, urlUserDeletePaymentRow, urlUserGetPaymentListPageDetail } from "./CONSTANTS";

export const apiGetAdminBansPageDetail = () => {
  const url = urlAdminGetReportsPageDetail;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, SYSTEM_ERROR)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
};
export function apiAdminDeleteBan(params) {
  const url = urlAdminDeleteReport;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", params.id);
  
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 

export function apiAdminUpdateBanStatus(params) {
  const url = urlAdminUpdateReportStatus;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", params.id);
  formData.append("status", params.status);
  
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 
 
 
  