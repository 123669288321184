import { APP_NAME, SYSTEM_ERROR } from "config/CONSTANTS";
import { curl_post, empty } from "utils/GlobalFunctions";
import { axiosGet, axiosPost } from "./ajaxServices";
import {urlAdminDeleteUser, urlAdminDownloadUserList, urlAdminGetTwilioChannel, urlAdminGetTwilioToken, urlAdminGetUserDetail, urlAdminGetUsersPageDetail, urlAdminSendMessage, urlAdminSetUserTmpPassword, urlAdminUpdateUserInfo, urlAdminUpdateUserStatus, urlUserDeletePaymentRow, urlUserGetPaymentListPageDetail } from "./CONSTANTS";

export const apiGetAdminUsersPageDetail = () => {
  const url = urlAdminGetUsersPageDetail;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, SYSTEM_ERROR)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
};
export function apiAdminGetUserDetail(params) {
  const url = urlAdminGetUserDetail ;
  return new Promise((resolve, reject) => {
    axiosGet(url, params)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  })
} 
export function apiAdminDeleteUser(params) {
  const url = urlAdminDeleteUser;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("user_id", params.user_id);
  
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 
export function apiAdminUpdateUserInfo(params) {
  const url = urlAdminUpdateUserInfo;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", params.id);
  formData.append("name", params.name.trim());
  formData.append("status_message", params.status_message);
  formData.append("gender_id", params?.gender?.id);
  formData.append("looking_for_genders", JSON.stringify(params.looking_for_genders));
  if(params.keyword_arr) {
    formData.append("keywords",  params.keyword_arr.join(','));
  }else{
    formData.append("keywords",  "");
  }

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
}
export function apiAdminUpdateUserStatus(params) {
  const url = urlAdminUpdateUserStatus;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", params.id);
  formData.append("user_status", params.user_status);
  if(params['ban_reason']) {
    formData.append("ban_reason", params.ban_reason);
  }
  if(params['ban_desc']) {
    formData.append("ban_desc", params.ban_desc);
  }

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 

////////// twilio chat/////////////////////////////
export const apiAdminGetTwilioToken = async(params = {}) => {
  const url = urlAdminGetTwilioToken;
  let promiseRes = new Promise((resolve, reject) => {
    axiosGet(url, params)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
  let res = await promiseRes
  return res
} 
export const apiAdminGetTwilioChannel = async(params) => {
  const url = urlAdminGetTwilioChannel;
  let promiseRes = new Promise((resolve, reject) => {
    axiosGet(url, params)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });  
  let res = await promiseRes
  return res 
}
export function apiAdminSendMessage(params) {
  const url = urlAdminSendMessage;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("user_id", params.user_id);
  formData.append("message", params.message);
  if(!empty(params['report_id'])) {
    formData.append("report_id", params.report_id);
  }

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 

  