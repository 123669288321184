import { ROUTE_LOGIN } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import {
  empty,
  get_data_value,
  initAppNavMenu,
  is_empty,
} from "utils/GlobalFunctions";
import "./AdminLayout.css";

import { SOCKET_SERVER_URL } from "config/CONSTANTS";
import io from "socket.io-client";
import { updateSocket } from "redux/actions/socketActions";
import AdminLoader from "./Includes/AdminLoader";
import AdminSidebar from "./Includes/AdminSidebar";
import AdminHeader from "./Includes/AdminHeader";
import AdminFooter from "./Includes/AdminFooter";
import ConfirmLogoutModal from "components/ConfirmLogoutModal/ConfirmLogoutModal";
import { console_log } from "utils/logger";

const AdminLayout = (props) => {
  const dispatch = useDispatch();

  const appDataStore = useSelector((x) => x.appDataStore);
  const userDataStore = useSelector((x) => x.userDataStore);
  const socketStore = useSelector((x) => x.socketStore);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    initAppNavMenu();
  }, []);

  ///////////////////////////////////socket part///////////////////////////////////////////
  const token = get_data_value(userDataStore, "token");

  ///////////////////////////////////end socket part///////////////////////////////////////////
  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);
  const askLogout = () => {
    console.log("askLogout!");
    setShowConfirmLogoutModal(true);
  };

  const doLogout = () => {
    setShowConfirmLogoutModal(false);
    history.push(ROUTE_LOGIN);
  };

  const toggleMUserSideBar = () => {
    dispatch(
      UpdateAppData({
        m_user_menu_collapsed: appDataStore.m_user_menu_collapsed
          ? false
          : true,
      })
    );
  };

  const checkLogin = () => {
    let token = get_data_value(userDataStore, "token");
    let is_admin = userDataStore["is_admin"];
    return !is_empty(token) && is_admin === "1"; //if valid logged in (for admin)
  };

  if (!checkLogin()) {
    return <Redirect to={ROUTE_LOGIN} />;
  }
  return (
    <div
      className={`admin-page page-vertical-layout ${
        appDataStore.m_user_menu_collapsed ? "" : "menu-open"
      }`}
    >
      <AdminLoader></AdminLoader>
      
      {/* <!-- BEGIN: Header--> */}
      <AdminHeader
        askLogout={askLogout}
        toggleMUserSideBar={toggleMUserSideBar}
      ></AdminHeader>
      {/* <!-- END: Header--> */}

      {/* <!-- BEGIN: Main Menu--> */}
      <AdminSidebar
        askLogout={askLogout}
        toggleMUserSideBar={toggleMUserSideBar}
      ></AdminSidebar>
      {/* <!-- END: Main Menu--> */}

      {/* <!-- BEGIN: Content--> */}
      <div className="app-content content ">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper p-0">
          <div className="content-body">
            <div>{props.children}</div>
          </div>
        </div>
      </div>
      {/* <!-- END: Content--> */}

      <div
        className="sidenav-overlay"
        onClick={(e) => {
          toggleMUserSideBar();
        }}
      ></div>
      <div className="drag-target"></div>

      {/* <!-- BEGIN: Footer--> */}
      <button className="btn btn-primary btn-icon scroll-top" type="button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-arrow-up"
        >
          <line x1="12" y1="19" x2="12" y2="5"></line>
          <polyline points="5 12 12 5 19 12"></polyline>
        </svg>
      </button>
      <AdminFooter></AdminFooter>
      {/* <!-- END: Footer--> */}

      <ConfirmLogoutModal
        onClickYes={doLogout}
        visibleModal={showConfirmLogoutModal}
        setVisibleModal={setShowConfirmLogoutModal}
        modalClass="admin-page confirm-modal"
      />
    </div>
  );
};
export default AdminLayout;
