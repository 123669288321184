import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  empty,
  get_data_value,
  intval,
  isEmpty,
  jQuery,
  showToast,
} from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";
import { console_log } from "utils/logger";

function AdminComplimentAnimationModal(props) {
  const {
    isVisible,
    setVisibleModal,
    pageData,
    currentRow,
    submitModalData,
    modalClass,
    modalTitle,
  } = props;
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      setModalPageData(pageData);
      if (!empty(currentRow)) {
        setModalData({ ...defaultModalData, ...currentRow });
        
        setUploadFile(null)
        setFileUploadPlaceholder("No file selected")
        setUploadAnimateFile(null)
        setAnimateFileUploadPlaceholder("No file selected")
      }
    }
  }, [isVisible, pageData, currentRow]);
  const [show, setShow] = useState(false);
  const defaultModalData = {
    id: 0,
    name: "",
  };
  const [modalPageData, setModalPageData] = useState({});
  const [modalData, setModalData] = useState(defaultModalData);
  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(modalData, "name", errorList);
    setErrorField([...errorList]);
    if(errorList.length === 0) {
      if(modalData['id'] === 0) {
        if(uploadFile === null){
          showToast("Please choose image file", "error")
          return false;
        }
        if(uploadAnimateFile === null){
          showToast("Please choose animated file", "error")
          return false;
        }
      }
      return true;
    }else{
      return false
    }
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let updateData = {};
    updateData[field_name] = e.target.value;
    setModalData({ ...modalData, ...updateData });
  };
  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  const onClickSubmitModal = () => {
    let is_valid = validateFields();
    if (is_valid) {
      submitModalData({ ...modalData, id: currentRow["id"], uploadFile:uploadFile, uploadAnimateFile:uploadAnimateFile});
    }
  };

  const [uploadFile, setUploadFile] = useState(null);
  const [fileUploadPlaceholder, setFileUploadPlaceholder] =
    useState("No file selected");
  const [fileKey, setFileKey] = useState("upload_file");
  const onFileInputChanged = (e) => {
    console_log("e::::", e)
    if(empty(e)) {
      setUploadAnimateFile(null);
      setAnimateFileUploadPlaceholder(null);
      return false;
    }
    setUploadFile(e.target.files[0]);
    let files = e.target.files;
    if (!empty(files)) {
      setFileUploadPlaceholder(files[0].name);
    } else {
      setFileUploadPlaceholder("No file selected");
    }
  };

  const [uploadAnimateFile, setUploadAnimateFile] = useState(null);
  const [animateFileUploadPlaceholder, setAnimateFileUploadPlaceholder] =
    useState("No file selected");
  const [animateFileKey, setAnimateFileKey] = useState("upload_animate_file");
  const onAnimateFileInputChanged = (e) => {
    if(empty(e)) {
      setUploadAnimateFile(null);
      setAnimateFileUploadPlaceholder(null);
      return false;
    }
    setUploadAnimateFile(e.target.files[0]);
    let files = e.target.files;
    if (!empty(files)) {
      setAnimateFileUploadPlaceholder(files[0].name);
    } else {
      setAnimateFileUploadPlaceholder("No file selected");
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-center">
            <div className="modal-box">
              <div id="dialog">
                <div className="text-normal text-left">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="mb-1">
                        <label className="form-label" htmlFor="name">
                          Animation Name
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder=" "
                          id="name"
                          name="name"
                          className={`form-control name ${
                            errorField.includes("name") ? "is-invalid" : ""
                          }`}
                          value={modalData["name"]}
                          onChange={(e) => {
                            onChangeFormField(e, "name");
                          }}
                        />
                      </div>
                      <div className="mb-1">
                        <div className="form-group">
                          <label className="form-label">Image File</label>
                          <div className="input-group file-upload-group col-xs-12">
                            <input
                              type="text"
                              className="form-control file-upload-info"
                              readOnly
                              placeholder={fileUploadPlaceholder}                             
                              style={{ borderColor: "#313452 !important" }}
                            />
                            <button
                              className="file-upload-browse btn btn-info"
                              type="button"
                            >
                              Browse
                            </button>
                            <input
                              type="file"
                              name="upload_file"
                              className="file-upload-default upload_file"
                              data-key={fileKey}
                              key={fileKey}
                              onChange={(e) => onFileInputChanged(e)}
                              accept="image/png"
                            />
                          </div>
                          {/* <p className="card-description mt-50">Only allowed (JPEG, JPG, PNG)</p> */}
                        </div>
                      </div>
                      <div className="mb-1">
                        <div className="form-group">
                          <label className="form-label">Animated File</label>
                          <div className="input-group file-upload-group col-xs-12">
                            <input
                              type="text"
                              className="form-control file-upload-info"
                              readOnly
                              placeholder={animateFileUploadPlaceholder}
                              style={{ borderColor: "#313452 !important" }}
                            />
                            <button
                              className="file-upload-browse btn btn-info"
                              type="button"
                            >
                              Browse
                            </button>
                            <input
                              type="file"
                              name="upload_animate_file"
                              className="file-upload-default upload_file"
                              data-key={animateFileKey}
                              key={animateFileKey}
                              onChange={(e) => onAnimateFileInputChanged(e)}
                              accept=".json"
                            />
                          </div>
                          {/* <p className="card-description mt-50">Only allowed (JPEG, JPG, PNG)</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-md btn-submit-modal-data btn-primary"
            onClick={onClickSubmitModal}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminComplimentAnimationModal;
