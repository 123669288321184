import React, { Fragment } from 'react';
import { useEffect } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from 'react-table';
import { Table, Row, Col, Button, Input, CustomInput } from 'reactstrap';
import { Filter, DefaultColumnFilter } from './filters';

const TableContainer = ({ columns, data, renderRowSubComponent, subHeaderComponent, paginationComponent, onFilterKeyChange, onSortChange, filterObject, perPageSize }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      autoResetSortBy: false,
      disableSortRemove: true,
      defaultColumn: { Filter: (param) => DefaultColumnFilter({ ...param, filterObject: filterObject, onFilterKeyChange: onFilterKeyChange }) },
      initialState: {
        pageIndex: 0, pageSize: perPageSize, sortBy: [
          {
            id: 'created_at',
            desc: true
          }
        ]
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    setPageSize(perPageSize);
  }, [perPageSize]);

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : '';
  };

  const onClickSortChange = (columnId, desc) => {
    console.log(columnId);
    if (columnId === "Action") return;
    setSortBy([{ id: columnId, desc }]);
    onSortChange(columnId, desc);
  };

  return (
    <Fragment>
      <Row style={{ width: "100%", margin: '0 auto', marginBottom: 20, textAlign: 'center' }}>
        {
          subHeaderComponent()
        }
      </Row>
      <Table hover {...getTableProps()}>
        <thead >
          {headerGroups.map((headerGroup, groupIndex) => (
            <tr key={groupIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column.id} {...column.getHeaderProps()}>
                  {/* <div {...column.getSortByToggleProps()} style={{width:column.render('Header') === 'Gender' ? column.render('width') : 'auto', height:30, textAlign:'left'}}> */}
                  <div {...(column.sortable ? column.getSortByToggleProps() : {})}
                    style={{
                      cursor: column.sortable ? 'pointer' : 'default',
                      width: column.render('width'), minWidth: column.render('minWidth'),
                      maxWidth: column.render('maxWidth'),
                      height: 30,
                      fontSize: '12px',
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.87)',
                      textAlign: 'left'
                    }}
                    onClick={() => {
                      if (column.sortable) {
                        onClickSortChange(column.id, !column.isSortedDesc)
                      }
                    }}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </div>
                  <Filter column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <Row style={{ width: "100%", margin: '0 auto', marginBottom: 20, textAlign: 'center' }}>
        {
          paginationComponent()
        }
      </Row>
    </Fragment>
  );
};

export default TableContainer;
