import { axiosGet, axiosPost } from "./ajaxServices";
import { urlCheckUserName, urlConfirmPassword, urlLogin, urlLoginTwoFactAuth, urlLogout, urlRequestResetPassword, urlResetPassword } from "./CONSTANTS";

export function apiCheckUserName(userData) {
  const url = urlCheckUserName;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("username", userData.user_name);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
}

export function apiLogin(userData) {
    const url = urlLogin;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("username", userData.user_name);
    formData.append("password", userData.user_password); 
  
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });   
}

export function apiLoginTwoFactAuth(userData) {
  const url = urlLoginTwoFactAuth;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("username", userData.user_name);
  formData.append("password", userData.user_password); 
  formData.append("code", userData.code); 

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
}

export function apiRequestResetPassword(userData) {
  const url = urlRequestResetPassword;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("username", userData.user_name);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
}

export function apiResetPassword(userData) {
  const url = urlResetPassword;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("username", userData.user_name);
  formData.append("reset_code", userData.reset_code);
  formData.append("user_password", userData.user_password);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
}
export function apiLogout(token) {
  const url = urlLogout;
  const get_params = {token:token}
  return new Promise((resolve, reject) => {
    axiosGet(url, get_params, "")
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
}

 
 
 
 