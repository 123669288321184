import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import axios from "axios";

import {
  curl_post,
  intval,
  showToast,
  show_loading,
} from "utils/GlobalFunctions";
import {
  urlAdminDeleteReport,
  urlAdminDeleteUser,
  urlAdminDownloadUserList,
  urlAdminGetReportList,
  urlAdminGetUsersList,
} from "services/CONSTANTS";

import {
  apiAdminDeleteUser,
  apiAdminGetUserDetail,
  apiAdminUpdateUserInfo,
  apiAdminUpdateUserStatus,
} from "services/adminUsersService";
import AdminUserInfoModal from "pages/Admin/AdminUsersPage/inc/AdminUserInfoModal";
import { console_log } from "utils/logger";
import { Link, useHistory } from "react-router-dom";
import AdminReportInfoModal from "./AdminReportInfoModal";
import { ROUTE_ADMIN_USER_DETAIL } from "navigation/CONSTANTS";
import { apiAdminDeleteReport, apiAdminUpdateReportStatus } from "services/adminReportsService";
import AdminUserEditModal from "pages/Admin/AdminUsersPage/inc/AdminUserEditModal";

const AdminReportListTable = (props) => {
  const userDataStore = useSelector((x) => x.userDataStore);
  const { pageData } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // const [deleted, setDeleted] = useState([]);
  const [currentSortField, setCurrentSortField] = useState("created_at");
  const [currentSortDirection, setCurrentSortDirection] = useState("desc");

  const [filterText, setFilterText] = useState("");

  const fetchDataList = async (
    page,
    size = perPage,
    searchText = filterText,
    sortColumn = currentSortField,
    sortDirection = currentSortDirection
  ) => {
    show_loading(true);

    const response = await axios.get(
      `${urlAdminGetReportList}?page=${page}&per_page=${size}&keyword1=${searchText}&sort_column=${sortColumn}&sort_direction=${sortDirection}`
    );

    setData(response.data.data);
    setTotalRows(response.data.total);
    show_loading(false);
  };

  useEffect(() => {
    fetchDataList(1);
  }, []);

  const history = useHistory();

  const removeItem = (array, item) => {
    const newArray = array.slice();
    newArray.splice(
      newArray.findIndex((a) => a === item),
      1
    );
    return newArray;
  };

  const handleDelete = useCallback(
    (row) => async () => {
      await axios.delete(`${urlAdminDeleteReport}?id=${row.id}`);
      const response = await axios.get(
        `${urlAdminGetReportList}?page=${currentPage}&per_page=${perPage}&keyword1=${filterText}&sort_column=${currentSortField}&sort_direction=${currentSortDirection}`
      );

      setData(removeItem(response.data.data, row));
      setTotalRows(totalRows - 1);
    },
    [currentPage, perPage, totalRows]
  );
  const handlePageChange = (page) => {
    fetchDataList(page);
    setCurrentPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    fetchDataList(page, newPerPage);
    setPerPage(newPerPage);
  };
  const handleSearchChange = (newSearchText) => {
    fetchDataList(currentPage, perPage, newSearchText);
    setFilterText(newSearchText);
  };
  const onSort = (column, sortDirection, event) => {
    let sortColumn = column["selector"];
    setCurrentSortField(sortColumn);
    setCurrentSortDirection(sortDirection);
    fetchDataList(currentPage, perPage, filterText, sortColumn, sortDirection);
  };
  const reloadTable = () => {
    fetchDataList(
      currentPage,
      perPage,
      filterText,
      currentSortField,
      currentSortDirection
    );
  };

  const columns = useMemo(
    () => [
      // {
      //   name: "ID",
      //   selector: "id",
      //   sortable: true,
      // },
      {
        name: "Reported by",
        selector: "report_user_name",
        sortable: true,
        cell: (row) => (
          <div className="user-avatar-box td-wrapper">
            {row["report_user_name"]}
          </div>
        ),
      },
      {
        name: "Reported User",
        selector: "reported_user_name",
        sortable: true,
        cell: (row) => (
          <div className="user-avatar-box td-wrapper">
            <a href="#" onClick={() => onClickEditUser(row, "reported_user")} title="Edit reported user">
              {row["reported_user_name"]}
            </a>
          </div>
        ),
      },
      {
        name: "Reason",
        selector: "reason",
        sortable: true,
        cell: (row) => (
          <div
            className="td-wrapper"
            onClick={(e) => {
              onClickEditItem(row);
            }}
          >
            {row.reason}
          </div>
        ),
      },
      {
        name: "Description",
        selector: "description",
        sortable: true,
        cell: (row) => (
          <div
            className="td-wrapper"
            onClick={(e) => {
              onClickEditItem(row);
            }}
          >
            <div className="text-emphasis-2">
              {row.description}
            </div>
          </div>
        ),
      },
      {
        name: "Reported Date",
        selector: "created_at",
        sortable: true,
        cell: (row) => (
          <span
            onClick={(e) => {
              onClickEditItem(row);
            }}
          >
            {row.created_at.substr(0, 10)}
          </span>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        cell: (row) => (
          <div
            className="td-wrapper"
            onClick={(e) => {
              onClickEditItem(row);
            }}
          >
            <span className={`badge me-25 ms-25 ${row.status === 'New' ? "bg-light-danger" : row.status === 'Messaged' ? "bg-light-info" : row.status === 'Banned' ? "bg-light-warning" : "bg-dark"} `}>{row.status}</span>
          </div>
        ),
      },
      {
        name: "Action",
        omit: true,
        // eslint-disable-next-line react/button-has-type
        cell: (row) => (
          <>
            <button
              type="button"
              className="btn btn-icon td-btn btn-info"
              title="Detail"
              onClick={() => onClickEditItem(row)}
            >
              <i className="las la-eye"></i>
            </button>
            <button
              type="button"
              className="btn btn-icon td-btn btn-danger"
              title="Delete"
              onClick={() => onClickDeleteItem(row)}
            >
              <i className="las la-trash"></i>
            </button>
          </>
        ),
      },
    ],
    [handleDelete]
  );
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // const filteredItems = data.filter(
  //   (item) =>
  //     JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
  //     -1
  // );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        handleSearchChange("");
      }
    };

    return (
      <div className="datatable-subheader">
        <div className="row">
          <div className="col-md-12">
            <div className="datatable-filter-box pull-right">
              <input
                id="search"
                type="text"
                placeholder="Search..."
                value={filterText}
                className="form-control input-search"
                onChange={(e) => handleSearchChange(e.target.value)}
              />
              <button className="a-btn btn-clear-search" onClick={handleClear}>
                x
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  //////////////////////////////////////////start modal actions///////////////////////////////////////////////
  const [currentRow, setCurrentRow] = useState({});
  const [currentReport, setCurrentReport] = useState({});
  const [currentReportedUser, setCurrentReportedUser] = useState({});

  const [showItemInfoModal, setShowItemInfoModal] = useState(false);
  const [showUserEditModal, setShowUserEditModal] = useState(false);
  const [userEditModalTitle, setUserEditModalTitle] = useState("Edit User");

  const [showUserInfoModal, setShowUserInfoModal] = useState(false);

  const updateUserInfo = (params) => {
    show_loading(true);
    apiAdminUpdateUserInfo(params)
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setShowUserInfoModal(false);
          showToast("User has been updated successfully", "success");

          fetchDataList(currentPage, perPage);
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  const onRowsClicked = (row, event) => {
    onClickEditItem(row);
  };
  const onClickEditItem = (row) => {
    setCurrentRow(row);

    let user_id = row['reported_user_id']
    let params = {
      user_id: user_id,
    };
    show_loading(true);
    apiAdminGetUserDetail(params)
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          let user_info = api_res["data"]["user"];
          setCurrentReportedUser(user_info)
          setShowItemInfoModal(true);
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });

  };

  const onClickEditUser = (row, user_type) => {
    setCurrentReport(row)

    let user_id = row['reported_user_id']
    if (user_type === "report_user") {
      setUserEditModalTitle("Edit Report User")
    } else {
      setUserEditModalTitle("Edit Reported User")
    }
    let params = {
      user_id: user_id,
    };
    show_loading(true);
    apiAdminGetUserDetail(params)
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          let user_info = api_res["data"]["user"];
          setCurrentRow(user_info);
          setShowUserInfoModal(true);
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };

  const deleteItem = (row) => {
    //delete item from modal
    setCurrentRow(row);
    setConfirmedAction("delete_item");
    doConfirmedAction();
    setShowItemInfoModal(false);
  };
  const suspendUser = (row) => {
    show_loading(true);
    apiAdminUpdateReportStatus({
      id: row["id"],
      status: "Banned",
    })
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        setShowItemInfoModal(false);
        reloadTable();
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };
  const updateReportStatus = (row) => {
    show_loading(true);
    apiAdminUpdateReportStatus({
      id: row["id"],
      status: row['status'],
    })
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        setShowItemInfoModal(false);
        reloadTable();
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };
  const closeItem = (row) => {
    show_loading(true);
    apiAdminUpdateReportStatus({
      id: row["id"],
      status: "Closed",
    })
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        setShowItemInfoModal(false);
        reloadTable();
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };

  const onClickDeleteItem = (row) => {
    setCurrentRow(row);
    setConfirmModalTitle("Are you sure you want to delete?");
    setConfirmedAction("delete_item");
    setShowConfirmModal(true);
  };

  const [confirmModalTitle, setConfirmModalTitle] = useState(
    "Are you sure you want to delete?"
  );
  const [confirmedAction, setConfirmedAction] = useState("change_user_status");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const doConfirmedAction = () => {
    let params = {};
    if (confirmedAction === "delete_item") {
      params = {
        id: currentRow["id"],
      };
      show_loading(true);
      apiAdminDeleteReport(params)
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          setShowConfirmModal(false);
          if (api_res.status == 200) {
            reloadTable();
            showToast(api_res["message"], "success");
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  };

  const handlePagination = (page) => {
    handlePageChange(page.selected + 1);
  };
  const handlePageSize = (event) => {
    let page_size = event.target.value;
    console.log("page_size", page_size);
    let cur_page = 1; // currentPage
    setCurrentPage(cur_page);
    handlePerRowsChange(intval(page_size), cur_page);
  };
  // ** Custom Pagination
  const CustomPagination = () => {
    const count = Math.ceil(totalRows / perPage);
    const [pageInput, setPageInput] = useState(currentPage);
    const handlePageInput = (event) => {
      let page_number = event.target.value;
      console.log("page_number", page_number);
      setPageInput(intval(page_number));
    };
    const submitPageInput = () => {
      if (pageInput > 0 && pageInput <= Math.ceil(totalRows / perPage)) {
        handlePageChange(pageInput);
      } else {
        showToast("Invalid page number", "error");
      }
    };

    const onPageChangeKeyInput = (e) => {
      if (e.key === "Enter") {
        submitPageInput();
      }
    };

    return (
      <div className="datatable-footer-wrapper">
        <div className="page-size-select-box vhalign">
          <span>Rows per page:&nbsp;</span>
          <select
            className="page-size-select"
            aria-label="Rows per page:"
            onChange={(e) => {
              handlePageSize(e);
            }}
            value={perPage}
          >
            {[5, 10, 25, 50, 100].map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <ReactPaginate
          previousLabel={""}
          nextLabel={""}
          breakLabel="..."
          pageCount={Math.ceil(count) || 1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          forcePage={currentPage !== 0 ? currentPage - 1 : 0}
          onPageChange={(page) => handlePagination(page)}
          activeClassName="active"
          pageClassName="page-item"
          breakClassName="page-item"
          nextLinkClassName="page-link"
          pageLinkClassName="page-link"
          breakLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextClassName="page-item next-item"
          previousClassName="page-item prev-item"
          containerClassName={"pagination"}
        />
        <div className="page-number-input-box vhalign">
          <span>Go to page:&nbsp;</span>
          <input
            type="number"
            placeholder=""
            min="0"
            value={pageInput}
            className="page-number-input"
            onChange={(e) => handlePageInput(e)}
            onKeyDown={(e) => {
              onPageChangeKeyInput(e);
            }}
          />
          <button
            type="button"
            className="btn btn-primary btn-sm btn-submit-page-number"
            onClick={(e) => {
              submitPageInput();
            }}
          >
            Go
          </button>
        </div>
      </div>
    );
  };

  ////////////////////////////////////////////end modal actions/////////////////////////////////////////////////////////

  return (
    <>
      <div className="datatable-wrapper">
        <DataTable
          theme="default"
          highlightOnHover={true}
          pointerOnHover={true}
          noHeader={true}
          columns={columns}
          data={data}
          defaultSortField={currentSortField}
          defaultSortAsc={currentSortDirection === "asc"}
          onSort={onSort}
          sortServer={true}
          subHeader
          subHeaderComponent={subHeaderComponent}
          pagination
          paginationServer
          // paginationTotalRows={totalRows}
          // paginationDefaultPage={currentPage}
          // onChangeRowsPerPage={handlePerRowsChange}
          // onChangePage={handlePageChange}
          // paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginationComponent={CustomPagination}
          onRowClicked={onRowsClicked}
        />
      </div>

      <AdminReportInfoModal
        modalTitle={`Report Detail`}
        isVisible={showItemInfoModal}
        setVisibleModal={setShowItemInfoModal}
        pageData={pageData}
        currentRow={currentRow}
        suspendUser={suspendUser}
        updateReportStatus={updateReportStatus}
        deleteItem={deleteItem}
        closeItem={closeItem}
        reloadReportTable={reloadTable}
        currentReportedUser={currentReportedUser}
        modalClass="user-page admin-page report-info-modal modal-lg"
      />

      <AdminUserInfoModal
        modalTitle={`User detail`}
        isVisible={showUserInfoModal}
        setVisibleModal={setShowUserInfoModal}
        pageData={pageData}
        currentRow={{ ...currentRow, report_id: currentReport['id'] }}
        submitModalData={updateUserInfo}
        reloadReportTable={reloadTable}
        modalClass="user-page admin-page user-info-modal modal-lg"
      />

      <AdminUserEditModal
        modalTitle={userEditModalTitle}
        isVisible={showUserEditModal}
        setVisibleModal={setShowUserEditModal}
        pageData={pageData}
        currentRow={currentRow}
        submitModalData={(e) => {
          fetchDataList(currentPage, perPage);
        }}
        modalClass="user-page admin-page user-info-modal modal-lg"
      />

      <ConfirmModal
        content={confirmModalTitle}
        onClickYes={doConfirmedAction}
        visibleModal={showConfirmModal}
        setVisibleModal={setShowConfirmModal}
        modalClass="user-page admin-page confirm-modal"
      />
    </>
  );
};

export default AdminReportListTable;
