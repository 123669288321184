import React, { useState } from "react";
import "rsuite/dist/rsuite.css";
import "./filter.css"

import { DateRangePicker } from 'rsuite';
import { empty, getDates, get_normal_datetime } from "./GlobalFunctions";

export function dateBetweenFilterFn(rows, id, filterValues) {

  console.log("filterValues", filterValues);
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;
  if (ed || sd) {
    return rows.filter((r) => {
      // format data
      var dateAndHour = r.values[id].split(" ");
      var [year, month, day] = dateAndHour[0].split("-");
      var date = [month, day, year].join("/");
      var hour = dateAndHour[1];
      var formattedData = date + " " + hour;

      const cellDate = new Date(formattedData);

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
}

export function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
  filterObject,
  onFilterKeyChange
}) {

  const handleDateChange = (dates) => {
    console.log("handleDateChange", dates);
    setValue(dates);
    if (empty(dates)) {
      onFilterKeyChange(id, ',');      
    } else {
      //startDate = dates[0].toISOString().substring(0, 10);
      //endDate = dates[1].toISOString().substring(0, 10);
      startDate = get_normal_datetime(dates[0], false);
      endDate = get_normal_datetime(dates[1], false);
      console.log("old1", startDate);
      console.log("old2", endDate);
      onFilterKeyChange(id, `${startDate},${endDate}`);
    }
  }

  const handleDateRangeChange = (dates) => {
    setValue(dates);
    console.log("dates", dates);
    if (empty(dates)) {
      onFilterKeyChange(id, ',');
    } else {
      //startDate = dates[0].toISOString().substring(0, 10);
      //endDate = dates[1].toISOString().substring(0, 10);
      startDate = get_normal_datetime(dates[0], false);
      endDate = get_normal_datetime(dates[1], false);
      console.log("startDate", startDate);
      console.log("endDate", endDate);
      onFilterKeyChange(id, `${startDate},${endDate}`);
    }
  };

  let startDate = filterObject[id]?.split(',')[0] ?? '';
  let endDate = filterObject[id]?.split(',')[1] ?? '';
  const [value, setValue] = useState(getDates(startDate, endDate));
  const { afterToday } = DateRangePicker;

  return (
    <div style={{ width: "240px" }}>
      <DateRangePicker
        className="my-date-range-picker"
        style={{ borderColor: "#ed3a78" }}
        value={value}
        onChange={handleDateRangeChange}
        onOk={handleDateChange}
        disabledDate={afterToday()} />
      {/* <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateRangePicker']}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={[null, null]}
              onChange={handleDateChange}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <input
                    {...startProps.inputProps}
                    value={startProps.inputProps.value || ''}
                    placeholder={startProps.inputProps.placeholder}
                  />
                  <input
                    {...endProps.inputProps}
                    value={endProps.inputProps.value || ''}
                    placeholder={endProps.inputProps.placeholder}
                  />
                </React.Fragment>
              )}
            />
          </DemoContainer>
        </LocalizationProvider>
      </StyledEngineProvider> */}
      {/* <input
        // min={min.toISOString().slice(0, 10)}
        style={{width: 100, height: 30, borderColor: "#d8d6de", borderRadius:3, color:'#6e6b7b', borderWidth:1}}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? val : undefined, old[1]]);
        }}
        type="date"
        value={filterValue[0] || ""}
      />
      <input
        style={{width: 100, height: 30, borderColor: "#d8d6de", borderRadius:3, color:"#6e6b7b", borderWidth:1, marginTop:5}}
        // max={max.toISOString().slice(0, 10)}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? val.concat("T23:59:59.999Z") : undefined,
          ]);
        }}
        type="date"
        value={filterValue[1]?.slice(0, 10) || ""}
      /> */}
    </div>
  );
}
