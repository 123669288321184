import {
  ADMIN_LEVEL_ALL,
  ADMIN_LEVEL_SUPERADMIN,
  ROUTE_ADMIN_ANIMATION,
  ROUTE_ADMIN_BANS,
  ROUTE_ADMIN_COMPLIMENT_ANIMATIONS,
  ROUTE_ADMIN_COMPLIMENT_MESSAGES,
  ROUTE_ADMIN_DASHBOARD,
  ROUTE_ADMIN_MESSAGE,
  ROUTE_ADMIN_PROFILE,
  ROUTE_ADMIN_REPORTS,
  ROUTE_ADMIN_USERS,
} from "navigation/CONSTANTS";


export const ADMIN_MENU_ITEMS = [
  {
    route: ROUTE_ADMIN_USERS,
    icon: "las la-user-friends",
    title: "Users",
    level: ADMIN_LEVEL_ALL,
    children: []
  },
  {
    route: "",
    icon: "las la-th-large",
    title: "Compliments",
    level: ADMIN_LEVEL_ALL,
    children: [
      {
        route: ROUTE_ADMIN_COMPLIMENT_MESSAGES,
        icon: "",
        title: "Messages",
        level: ADMIN_LEVEL_ALL,
      },
      {
        route: ROUTE_ADMIN_COMPLIMENT_ANIMATIONS,
        icon: "",
        title: "Animations",
        level: ADMIN_LEVEL_ALL,
      }
    ]
  },
  {
    route: ROUTE_ADMIN_REPORTS,
    icon: "las la-flag",
    title: "Reports",
    level: ADMIN_LEVEL_ALL,
    children: []
  },
  {
    route: ROUTE_ADMIN_BANS,
    icon: "las la-ban",
    title: "Bans",
    level: ADMIN_LEVEL_ALL,
    children: []
  },
  {
    route: ROUTE_ADMIN_PROFILE,
    icon: "las la-cog",
    title: "Settings",
    level: ADMIN_LEVEL_ALL,
    children: []
  },

];
