import { BASE_API_URL } from "config/CONSTANTS";

const api_version = "v1";
const url_prefix = "/admin";
export const urlCheckUserName = BASE_API_URL + "/" + api_version + url_prefix + "/check-username";
export const urlLogin = BASE_API_URL + "/" + api_version + url_prefix + "/login";
export const urlLoginTwoFactAuth = BASE_API_URL + "/" + api_version + url_prefix + "/login-two-fact-auth";
export const urlRequestResetPassword = BASE_API_URL + "/" + api_version + url_prefix + "/request-reset-password";
export const urlResetPassword = BASE_API_URL + "/" + api_version + url_prefix + "/reset-password";
export const urlLogout = BASE_API_URL + "/" + api_version + url_prefix + "/logout";

export const urlAdminGetProfile = BASE_API_URL + "/" + api_version + url_prefix + "/get-profile-info";
export const urlAdminUpdateProfile = BASE_API_URL + "/" + api_version + url_prefix + "/update-profile-info";
export const urlAdminUpdatePassword = BASE_API_URL + "/" + api_version + url_prefix + "/update-password";
export const urlAdminCheckCurrentPassword = BASE_API_URL + "/" + api_version + url_prefix + "/check-current-password";
export const urlAdminGetUsersPageDetail = BASE_API_URL + "/" + api_version + url_prefix + "/users/get-page-detail";
export const urlAdminGetUsersList = BASE_API_URL + "/" + api_version + url_prefix + "/users/get-data-list";
export const urlAdminGetFilteredUsers = BASE_API_URL + "/" + api_version + url_prefix + "/users/get-filtered-data";
export const urlAdminGetUserDetail = BASE_API_URL + "/" + api_version + url_prefix + "/users/get-detail";
export const urlAdminUpdateUserInfo = BASE_API_URL + "/" + api_version + url_prefix + "/users/update";
export const urlAdminUpdateUserStatus = BASE_API_URL + "/" + api_version + url_prefix + "/users/update-status";
export const urlAdminDeleteUser = BASE_API_URL + "/" + api_version + url_prefix + "/users/delete";
export const urlAdminDownloadUserList = BASE_API_URL + "/" + api_version + url_prefix + "/users/download";

export const urlAdminGetComplimentMessageList = BASE_API_URL + "/" + api_version + url_prefix + "/compliment/get-message-list";
export const urlAdminUpdateComplimentMessage = BASE_API_URL + "/" + api_version + url_prefix + "/compliment/update-message";
export const urlAdminDeleteComplimentMessage = BASE_API_URL + "/" + api_version + url_prefix + "/compliment/delete-message";

export const urlAdminGetComplimentAnimationList = BASE_API_URL + "/" + api_version + url_prefix + "/compliment/get-animation-list";
export const urlAdminUpdateComplimentAnimation = BASE_API_URL + "/" + api_version + url_prefix + "/compliment/update-animation";
export const urlAdminDeleteComplimentAnimation = BASE_API_URL + "/" + api_version + url_prefix + "/compliment/delete-animation";

export const urlAdminGetReportsPageDetail = BASE_API_URL + "/" + api_version + url_prefix + "/report/get-page-detail";
export const urlAdminGetReportList = BASE_API_URL + "/" + api_version + url_prefix + "/report/get-data-list";
export const urlAdminDeleteReport = BASE_API_URL + "/" + api_version + url_prefix + "/report/delete";
export const urlAdminUpdateReportStatus = BASE_API_URL + "/" + api_version + url_prefix + "/report/update-status";

export const urlAdminGetBansPageDetail = BASE_API_URL + "/" + api_version + url_prefix + "/ban/get-page-detail";
export const urlAdminGetBanList = BASE_API_URL + "/" + api_version + url_prefix + "/ban/get-data-list";
export const urlAdminUpdateBanStatus = BASE_API_URL + "/" + api_version + url_prefix + "/ban/update-status";


export const urlAdminGetTwilioToken = BASE_API_URL + "/" + api_version + "/twilio" + "/token";
export const urlAdminGetTwilioChannel = BASE_API_URL + "/" + api_version + "/twilio" + "/channel";
export const urlAdminSendMessage = BASE_API_URL + "/" + api_version + url_prefix + "/users/send-message";

export const urlGetAdminVerificationDetail = BASE_API_URL + "/" + api_version + url_prefix + "/verification/get-detail";
export const urlAdminCompleteVerification = BASE_API_URL + "/" + api_version + url_prefix + "/verification/complete";
export const urlAdminCancelVerification = BASE_API_URL + "/" + api_version + url_prefix + "/verification/cancel";
