// any app specific constants that you don't want to keep in config can go here.
export const APP_NAME = "Concha";
export const REGISTER_FUNC = "enabled";

let base_site_url = "";
let base_api_url = "";
let socket_server_url = "";
let base_upload_url = "";

let sms_func = "disabled";

export const BACKEND_LOCATION = "prod"; //"localhost", "dev", "prod"
if (BACKEND_LOCATION === "localhost") {
    base_site_url = "http://192.168.0.51"
    base_api_url = base_site_url + ":8089"
    socket_server_url = base_api_url
    base_upload_url = base_site_url + ""
    sms_func = "disabled";
} else if (BACKEND_LOCATION === "dev") {
    base_site_url = "http://188.166.242.105"
    base_api_url = "http://188.166.242.105:8089"
    socket_server_url = base_api_url
    base_upload_url = base_site_url
    sms_func = "enabled";
} else if (BACKEND_LOCATION === "prod") {
    base_site_url = "https://cms.concha.date"
    base_api_url = "https://api.concha.date"
    socket_server_url = base_api_url
    base_upload_url = base_site_url
    sms_func = "enabled";
}

export const BASE_SITE_URL = base_site_url
export const BASE_FRONT_URL = BASE_SITE_URL
export const BASE_API_URL = base_api_url
export const SOCKET_SERVER_URL = socket_server_url
export const BASE_UPLOAD_URL = base_upload_url
 
export const SITE_MODE = "live";
export const SMS_FUNC = sms_func;

export const SYSTEM_ERROR = "System error. Please try again later!";
export const APPSTORE_LINK = "https://apps.apple.com/us/app/h581656445"
