import { ROUTE_ADMIN_USERS } from "navigation/CONSTANTS";
import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import {
  apiAdminGetUserDetail,
  apiAdminUpdateUserInfo,
  apiAdminUpdateUserStatus,
} from "services/adminUsersService";
import { copyObject, empty, isEmpty, showToast, show_loading } from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import { InputTags } from "react-bootstrap-tagsinput";
// import "react-bootstrap-tagsinput/dist/index.css";

import { useHistory } from "react-router-dom";
import AdminUserPhotoBox from "./AdminUserPhotoBox";

function AdminUserEditModal(props) {
  const {
    isVisible,
    setVisibleModal,
    pageData,
    currentRow,
    submitModalData,
    modalClass,
    modalTitle,
  } = props;
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      if (!empty(currentRow)) {
        setPgageData(currentRow);
      }
    }
  }, [isVisible, currentRow]);
  const [show, setShow] = useState(false);

  //////////////////////////////////////////////////////////////////
  const GENDER_LIST = [
    {
      id: 1,
      name: "Male"
    },
    {
      id: 2,
      name: "Female"
    },
  ]


  const initUserData = {
    keyword_arr: [],
    user_photos: [],
  };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);

  const setPgageData = (user_info) => {
    setUserData({ ...user_info });
    setTags(user_info["keyword_arr"]);

    let user_image_list = [];
    for (let k in user_info["user_photos"]) {
      let row = user_info["user_photos"][k];
      user_image_list.push(row["original"]["url"]);
    }
    setImages([...user_image_list]);
    console_log("user_info", user_info);
  };

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(userData, "name", errorList);

    setErrorField([...errorList]);
    let isValid = errorList.length > 0 ? false : true;
    if(isValid) {
      const looking_for_genders = userData['looking_for_genders']
      if(looking_for_genders && looking_for_genders.length > 0) {
        return true
      }
      else{
        showToast("Please choose looking for gender", "error")
        return false
      }
    }
    return isValid
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let updateData = { ...userData };
    updateData[field_name] = e.target.value;
    setUserData({ ...updateData });
  };

  const submitData = () => {
    let is_valid = validateFields();
    //console_log("userData:::", userData)
    if (is_valid) {
      show_loading(true);
      apiAdminUpdateUserInfo({ ...userData, keyword_arr: tags })
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          if (api_res.status == 200) {
            let user_info = api_res["data"]["user"];
            setPgageData(user_info);
            showToast(api_res.message, "success");
            submitModalData();
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  };

  const updateUserStatus = (user_status) => {
    if (user_status) {
      show_loading(true);
      apiAdminUpdateUserStatus({ id: userData["id"], user_status: user_status })
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          if (api_res.status == 200) {
            let user_info = api_res["data"]["user"];
            setPgageData(user_info);
            showToast(api_res.message, "success");
            submitModalData();
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  };

  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  const [tags, setTags] = useState([]);
  const onChangeTags = (t) => {
    console_log("t::::", t);
    setTags(t.values);
  };

  const [images, setImages] = useState([]);

  ////////////////////////////////////////////////////////////////
  const adjustGenderObject = (item) =>{
    return {
      ...item,
       created_at: null, 
       updated_at: null
    }
  }
  const onChnageGender = (e, genderItem) => {
    const checked = e.target.checked
  //  console.log(genderItem, checked)
    const user_data = { ...userData }
    if (checked) {
      user_data['gender'] = adjustGenderObject(copyObject(genderItem)) 
      setUserData(user_data)
    }
  }

  const checkLookingForGender = (genderItem) => {
    let checked = false
    const looking_for_genders = userData?.looking_for_genders
    if (looking_for_genders) {
      for (let k in looking_for_genders) {
        if (genderItem.id === looking_for_genders[k].id) {
          checked = true
        }
      }
    }
    return checked
  }

  const onChnageLookingForGender = (e, genderItem) => {
    const checked = e.target.checked
  //  console.log(genderItem, checked)
    const user_data = { ...userData }
    let looking_for_genders = user_data?.looking_for_genders ?? []
    if (checked) {
      let checkItem = looking_for_genders.find((item)=>item.id === genderItem.id)
      if(checkItem && checkItem.id) {
        return false
      }else{
        looking_for_genders.push(adjustGenderObject(copyObject(genderItem)))
      }
      user_data['looking_for_genders'] = looking_for_genders
      setUserData(user_data)
    }else{
      looking_for_genders = looking_for_genders.filter((item)=>item.id !== genderItem.id)
      user_data['looking_for_genders'] = looking_for_genders
      setUserData(user_data)
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-left">
            <div className="modal-box">
              <div id="dialog">
                <div className="admin-user-detail-page">
                  <div className="row">
                    <div className="col-md-12 grid-margin">
                      <div className="">
                        <div className="">
                          <div className="">
                            <div className="row">
                              <div className="col-md-6">
                                <div
                                  style={{ maxWidth: "320px", margin: "auto" }}
                                >
                                  <AdminUserPhotoBox
                                    isVisible={true}
                                    photoList={images}
                                    boxClass={` `}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div>
                                  <div className="row">
                                    <div className="col-md-12 col-12">
                                      <div className="mb-1">
                                        <label
                                          className="form-label"
                                          htmlFor="name"
                                        >
                                          Name
                                        </label>
                                        <input
                                          type="text"
                                          className={`form-control name ${errorField.includes("name")
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          id="name"
                                          name="name"
                                          value={
                                            !empty(userData["name"])
                                              ? userData["name"]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onChangeFormField(e, "name");
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                      <div className="mb-1">
                                        <label
                                          className="form-label"
                                          htmlFor="email"
                                        >
                                          Email Address
                                        </label>
                                        <input
                                          type="text"
                                          className={`form-control email ${errorField.includes("email")
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          id="email"
                                          name="email"
                                          value={
                                            !empty(userData["email"])
                                              ? userData["email"]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onChangeFormField(e, "email");
                                          }}
                                          readOnly={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                      <div className="mb-1">
                                        <label
                                          className="form-label"
                                          htmlFor="phone_number"
                                        >
                                          Phone number
                                        </label>
                                        <input
                                          type="text"
                                          className={`form-control phone ${errorField.includes("phone_number")
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          id="phone_number"
                                          name="phone_number"
                                          value={
                                            !empty(userData["phone_number"])
                                              ? userData["phone_number"]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onChangeFormField(
                                              e,
                                              "phone_number"
                                            );
                                          }}
                                          readOnly={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                      <div className="mb-1">
                                        <label
                                          className="form-label"
                                          htmlFor="gender"
                                        >
                                          Gender
                                        </label>
                                        <div className="demo-inline-spacing1">
                                          {
                                            GENDER_LIST.map((item, index) => {
                                              return (
                                                <div className="form-check form-check-primary form-check-inline" key={index}>
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={`gender_${item.id}`}
                                                    id={`gender_${item.id}`}
                                                    value={item.id}
                                                    checked={item.id === userData?.gender?.id}
                                                    onChange={(e) => onChnageGender(e, item)}
                                                  />
                                                  <label className="form-check-label" htmlFor={`gender_${item.id}`}>{item.name}</label>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                      <div className="mb-1">
                                        <label
                                          className="form-label"
                                          htmlFor="looking_for_genders"
                                        >
                                          Looking for genders
                                        </label>
                                        <div className="demo-inline-spacing1">
                                          {
                                            GENDER_LIST.map((item, index) => {
                                              return (
                                                <div className="form-check form-check-primary form-check-inline" key={index}>
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`looking_for_gender_${item.id}`}
                                                    checked={checkLookingForGender(item)}
                                                    onChange={(e) => onChnageLookingForGender(e, item)}
                                                  />
                                                  <label className="form-check-label" htmlFor={`looking_for_gender_${item.id}`}>{item.name}</label>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-12 col-12">
                                      <div className="mb-1">
                                        <label
                                          className="form-label"
                                          htmlFor="status_message"
                                        >
                                          Status message
                                        </label>
                                        <input
                                          type="text"
                                          className={`form-control status_message ${errorField.includes(
                                            "status_message"
                                          )
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          id="status_message"
                                          name="status_message"
                                          value={
                                            !empty(userData["status_message"])
                                              ? userData["status_message"]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onChangeFormField(
                                              e,
                                              "status_message"
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                      <div className="mb-1">
                                        <label
                                          className="form-label"
                                          htmlFor="user_keyword_list"
                                        >
                                          Keywords
                                        </label>
                                        <div className="user_keyword_list tag-box">
                                          <InputTags
                                            values={tags}
                                            onTags={(value) =>
                                              onChangeTags(value)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-footer w-100">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <div className="button-group text-center">
                    <button
                      type="button"
                      className="mb-50 btn btn-lg1 btn-primary me-50"
                      onClick={(e) => {
                        submitData();
                      }}
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      className="btn mb-50 btn-lg1 btn-close-dlg btn-light"
                      data-dismiss="modal"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminUserEditModal;
