export const USER_LEVEL_ALL = "all";
export const USER_LEVEL_AFFILIATE = "affiliate";
export const USER_LEVEL_CUSTOMER = "customer";
export const ADMIN_LEVEL_ALL = "all";
export const ADMIN_LEVEL_SUPERADMIN = "superadmin";
export const ADMIN_LEVEL_ASSISTANT = "assistant";

export const ROOT = "/";
export const ROUTE_ROOT = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_CONFIRM_PASSWORD = "/confirm-password/:code";

export const ROUTE_ADMIN_DASHBOARD = "/admin/dashboard";
export const ROUTE_ADMIN_PROFILE = "/admin/profile/index";
export const ROUTE_ADMIN_USERS = "/admin/user/index";
export const ROUTE_ADMIN_USER_DETAIL = "/admin/user/detail/:userid";
export const ROUTE_ADMIN_REPORTS = "/admin/report/index";
export const ROUTE_ADMIN_REPORT_DETAIL = "/admin/report/detail/:reportid";
export const ROUTE_ADMIN_COMPLIMENT_MESSAGES = "/admin/compliments/messages";
export const ROUTE_ADMIN_COMPLIMENT_ANIMATIONS = "/admin/compliments/animations";
export const ROUTE_ADMIN_BANS = "/admin/ban/index";



