import AdminLayout from "layouts/AdminLayout/AdminLayout";
import {
  ROUTE_ADMIN_COMPLIMENT_ANIMATIONS,
  ROUTE_ADMIN_COMPLIMENT_MESSAGES,
  ROUTE_ADMIN_PROFILE,
  ROUTE_ADMIN_USERS,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import {
  apiAdminCheckCurrentPassword,
  apiAdminGetProfileInfo,
  apiAdminUpdatePassword,
  apiAdminUpdateProfile,
} from "services/adminCommonService";
import {
  apiAdminGetUserDetail,
  apiAdminUpdateUserInfo,
  apiAdminUpdateUserStatus,
} from "services/adminUsersService";
import { empty, isEmpty, showToast, show_loading } from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import { InputTags } from "react-bootstrap-tagsinput";
// import "react-bootstrap-tagsinput/dist/index.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { useHistory } from "react-router-dom";
import AdminConfirmPasswordModal from "./inc/AdminConfirmPasswordModal";
import AdminTwoAuthenticatorModal from "./inc/AdminTwoAuthenticatorModal";
import { apiAdminCancelVerification, apiAdminCompleteVerification, apiGetAdminVerificationDetail } from "services/adminVerificationService";
import MfaCodeModal from "components/MfaCodeModal/MfaCodeModal";

const AdminProfilePage = (props) => {
  const currentPageTitle = "Settings";

  const userDataStore = useSelector((x) => x.userDataStore);
  const history = useHistory();

  const dispatch = useDispatch();
  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_ADMIN_PROFILE,
        m_user_menu_collapsed: true,
      })
    );
  };
  useEffect(() => {
    initPage();
    getData();
  }, []);

  const setPgageData = (user_info) => {
    setUserData({ ...user_info });
    console_log("user_info", user_info);
  };

  const getData = () => {
    show_loading(true);
    let params = {};
    show_loading(true);
    apiAdminGetProfileInfo(params)
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          let user_info = api_res["data"]["user"];
          setPgageData(user_info);
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };

  const initUserData = {};
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(userData, "first_name", errorList);
    errorList = isEmpty(userData, "last_name", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let updateData = { ...userData };
    updateData[field_name] = e.target.value;
    setUserData({ ...updateData });
  };

  const submitData = () => {
    let is_valid = validateFields();
    //console_log("userData:::", userData)
    if (is_valid) {
      show_loading(true);
      apiAdminUpdateProfile({ ...userData })
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          if (api_res.status == 200) {
            let user_info = api_res["data"]["user"];
            setPgageData(user_info);
            dispatch(updateUser(user_info));
            showToast(api_res.message, "success");
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  };

  const validatePasswordFields = () => {
    var errorList = Array();
    errorList = isEmpty(userData, "new_password", errorList);
    errorList = isEmpty(userData, "c_new_password", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onClickSubmitPasswordData = () => {
    let is_valid = validatePasswordFields();
    //console_log("userData:::", userData)
    if (is_valid) {
      if (userData['new_password'] != userData['c_new_password']) {
        showToast("Password does not match", "error");
        return false
      }
      setCurrentAction("update_password")
      setShowPasswordModal(true)
    }
  }

  const submitPasswordData = () => {
    let is_valid = validatePasswordFields();
    //console_log("userData:::", userData)
    if (is_valid) {
      if (userData['new_password'] != userData['c_new_password']) {
        showToast("Password does not match", "error");
        return false
      }

      show_loading(true);
      apiAdminUpdatePassword({ ...userData })
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          if (api_res.status == 200) {
            let user_info = api_res["data"]["user"];
            showToast(api_res.message, "success");
            setUserData({ ...userData, new_password: "", c_new_password: "" })
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  }

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [currentAction, setCurrentAction] = useState("");

  const modalSubmittedCallback = (modalData) => {
    apiAdminCheckCurrentPassword({ ...modalData })
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          setShowPasswordModal(false)
          if (currentAction == "update_password") {
            submitPasswordData()
          } else if (currentAction == "update_profile") {
            submitData()
          }
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });

  }

  const [verificationData, setVerificationData] = useState({});
  const [showAuthenticatordModal, setShowAuthenticatorModal] = useState(false);

  const onClickAuthenticator = () => {
    apiGetAdminVerificationDetail()
      .then((api_res) => {
        console_log("api_res:::", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          setVerificationData(api_res.data)
          setCurrentAction("add_Authenticator")
          setShowAuthenticatorModal(true)
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  }

  const modalAuthenticatorSubmittedCallback = () => {
    setShowMfaCodeModal(true)
    setShowAuthenticatorModal(false)
  }

  const [showMfaCodeModal, setShowMfaCodeModal] = useState(false);
  const modalMfaCodeSubmittedCallback = (code) => {
    const payload = {
      secret: verificationData['two_fact_secret'],
      otp: code
    }
    apiAdminCompleteVerification(payload)
      .then((api_res) => {
        console_log("api_res:::", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          showToast("Two-Factor Authentication has been set!", "success")
          setShowMfaCodeModal(false)
          getData()
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  }

  const onClickCancelAuthenticator = () => {
    if (window.confirm("Are you sure?")) {
      apiAdminCancelVerification()
        .then((api_res) => {
          console_log("api_res:::", api_res);
          show_loading(false);
          if (api_res.status == 200) {
            showToast("Two-Factor Authentication has been cancelled!", "success")
            getData()
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  }

  return (
    <>
      <AdminLayout>
        <div className="admin-user-detail-page">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title t-show-mobile">
                    {currentPageTitle}
                  </h4>
                  <div className="card-content">
                    <div className="mb-1">
                      <label className="">Personal info</label>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label className="form-label" htmlFor="first_name">
                            First name
                          </label>
                          <input
                            type="text"
                            className={`form-control first_name ${errorField.includes("first_name")
                              ? "is-invalid"
                              : ""
                              }`}
                            id="first_name"
                            name="first_name"
                            value={
                              !empty(userData["first_name"])
                                ? userData["first_name"]
                                : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "first_name");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label className="form-label" htmlFor="last_name">
                            Last name
                          </label>
                          <input
                            type="text"
                            className={`form-control last_name ${errorField.includes("last_name")
                              ? "is-invalid"
                              : ""
                              }`}
                            id="last_name"
                            name="last_name"
                            value={
                              !empty(userData["last_name"])
                                ? userData["last_name"]
                                : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "last_name");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label className="form-label" htmlFor="email">
                            Email Address
                          </label>
                          <input
                            type="text"
                            className={`form-control email ${errorField.includes("email") ? "is-invalid" : ""
                              }`}
                            id="email"
                            name="email"
                            value={
                              !empty(userData["email"]) ? userData["email"] : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "email");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label className="form-label" htmlFor="phone_number">
                            Phone number
                          </label>
                          <input
                            type="text"
                            className={`form-control phone_number ${errorField.includes("phone_number")
                              ? "is-invalid"
                              : ""
                              }`}
                            id="phone_number"
                            name="phone_number"
                            value={
                              !empty(userData["phone_number"])
                                ? userData["phone_number"]
                                : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "phone_number");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-footer">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mt-2">
                            <div className="button-group text-center">
                              <button
                                type="button"
                                className="mb-50 btn btn-lg1 btn-primary me-50"
                                onClick={(e) => {
                                  submitData();
                                }}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="card-content">
                    <div className="mb-1">
                      <label className="">Change Password</label>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label className="form-label" htmlFor="new_password">
                            New password
                          </label>
                          <input
                            type="password"
                            className={`form-control new_password ${errorField.includes("new_password")
                              ? "is-invalid"
                              : ""
                              }`}
                            id="new_password"
                            name="new_password"
                            value={
                              !empty(userData["new_password"])
                                ? userData["new_password"]
                                : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "new_password");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label
                            className="form-label"
                            htmlFor="c_new_password"
                          >
                            Confirm new password
                          </label>
                          <input
                            type="password"
                            className={`form-control c_new_password ${errorField.includes("c_new_password")
                              ? "is-invalid"
                              : ""
                              }`}
                            id="c_new_password"
                            name="c_new_password"
                            value={
                              !empty(userData["c_new_password"])
                                ? userData["c_new_password"]
                                : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "c_new_password");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-footer">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mt-2">
                            <div className="button-group text-center">
                              <button
                                type="button"
                                className="mb-50 btn btn-lg1 btn-primary me-50"
                                onClick={(e) => {
                                  onClickSubmitPasswordData();
                                }}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="card-content">
                    <div className="mb-1">
                      <label className="">Two factor authentication</label>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-1">
                          <label className="form-label" htmlFor="new_password">
                            Authenticator app verification.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-footer">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mt-2">
                            <div className="button-group text-center">
                              {
                                (userData['mfa_status'] === 3) ? (
                                  <>
                                    <button
                                      type="button"
                                      className="mb-50 btn btn-lg1 btn-primary me-50"
                                      onClick={(e) => {
                                        onClickCancelAuthenticator();
                                      }}
                                    >
                                      Cancel Authenticator
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      className="mb-50 btn btn-lg1 btn-primary me-50"
                                      onClick={(e) => {
                                        onClickAuthenticator();
                                      }}
                                    >
                                      Add Authenticator
                                    </button>
                                  </>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              className="mb-50 btn btn-lg1 btn-light"
              onClick={(e) => {
                //getData()
                history.push(ROUTE_ADMIN_USERS);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <AdminConfirmPasswordModal
          modalTitle={`Confirm password`}
          isVisible={showPasswordModal}
          setVisibleModal={setShowPasswordModal}
          pageData=""
          submitModalData={modalSubmittedCallback}
          modalClass="user-page admin-page confirm-password-modal"
        />
        <AdminTwoAuthenticatorModal
          modalTitle={`Add Authenticator`}
          isVisible={showAuthenticatordModal}
          setVisibleModal={setShowAuthenticatorModal}
          pageData={verificationData}
          submitModalData={modalAuthenticatorSubmittedCallback}
          modalClass="user-page admin-page confirm-password-modal"
        />
        <MfaCodeModal
          isVisible={showMfaCodeModal}
          setVisibleModal={setShowMfaCodeModal}
          pageData=""
          submitModalData={modalMfaCodeSubmittedCallback}
          modalClass="user-page admin-page confirm-password-modal"
        />
      </AdminLayout>
    </>
  );
};

export default AdminProfilePage;
