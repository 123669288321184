import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { ROUTE_ADMIN_DASHBOARD, ROUTE_ADMIN_USERS, ROUTE_FORGOT_PASSWORD, ROUTE_LOGIN } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, useHistory, withRouter } from "react-router-dom";

import {
  empty,
  get_data_value,
  isEmpty,
  showToast,
  show_loading,
} from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import { apiCheckUserName, apiLogin, apiLoginTwoFactAuth, apiLogout } from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { APP_NAME } from "config/CONSTANTS";
import Ripples from "react-ripples";
import MfaCodeModal from "components/MfaCodeModal/MfaCodeModal";

const LoginPage = (props) => {
  const dispatch = useDispatch();
  const initPage = () => {
    let page_title = "Login";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_LOGIN,
        license_cancelled_message: "",
      })
    );
  };

  useEffect(() => {
    initPage();
    doLogout();
  }, []);

  //////////////////////////////////////////////////////////////////
  const history = useHistory();
  const userDataStore = useSelector((x) => x.userDataStore);
  const userInfo = userDataStore;
  const initUserData = { user_name: "", user_password: "" };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  const [curStep, setCurStep] = useState(1);
  const [isBusy, setIsBusy] = useState(false);

  const validateFields = () => {
    var errorList = Array();
    if (curStep === 1) {
      errorList = isEmpty(userData, "user_name", errorList);
    } else {
      errorList = isEmpty(userData, "user_password", errorList);
    }
    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };
  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = e.target.value;
    setUserData({ ...userData });
  };

  const onClickLogin = () => {
    if (isBusy) {
      return false;
    }
    let is_valid = validateFields();
    console_log("is valid", is_valid);
    if (is_valid) {
      setIsBusy(true);
      if (curStep === 1) {
        //check-username
        apiCheckUserName(userData)
          .then((api_res) => {
            console_log("api_res", api_res);
            setIsBusy(false);
            if (api_res.status == 200) {
              setCurStep(2);
            }
          })
          .catch((err) => {
            setIsBusy(false);
            showToast(err, "error");
          });
      } else {
        //login submit
        apiLogin(userData)
          .then((api_res) => {
            console_log("api_res", api_res);
            if (api_res.status == 200) {
              const mfa_required = api_res["data"]["mfa_required"]
              if (mfa_required) {
                setIsBusy(false);
                setShowMfaCodeModal(true)
              } else {
                let data = api_res["data"]['user'];
                doLogin(data);
              }
            }
          })
          .catch((err) => {
            setIsBusy(false);
            showToast(err, "error");
          });
      }
    }
  };

  const doLogin = (data) => {
    showToast("You are in!", "success");
    dispatch(updateUser(data));
    setTimeout(() => {
      show_loading(false);
      history.push(ROUTE_ADMIN_USERS);
    }, 500);
  };

  const doLogout = () => {
    if (empty(userDataStore["token"])) {
      return false;
    }
    dispatch(updateUser({ token: "" }));
  };
  const onChangeKeyInput = (e, field_name) => {
    if (field_name === "user_name" || field_name === "user_password") {
      if (e.key === "Enter") {
        onClickLogin();
      }
    }
  };

  const [showMfaCodeModal, setShowMfaCodeModal] = useState(false)
  const modalMfaCodeSubmittedCallback = (code) => {
    const payload = {
      ...userData,
      code: code
    }
    apiLoginTwoFactAuth(payload)
      .then((api_res) => {
        console_log("api_res", api_res);
        if (api_res.status == 200) {
          let data = api_res["data"]['user'];
          doLogin(data);
          setShowMfaCodeModal(false)
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }

  return (
    <div>
      <HomeLayout>
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <div className="auth-wrapper auth-basic px-2">
              <div className="auth-inner my-2">
                <div className="card mb-0">
                  <div className="card-body">
                    <a href="#" className="brand-logo">
                      <img src="/assets/images/logo.png" className="logo-img" />
                      <h2 className="brand-text text-primary text-center mt-1">
                        {APP_NAME}
                      </h2>
                    </a>
                    <h4 className="card-title text-center mb-1">
                      Sign into your account
                    </h4>
                    <p className="card-text mb-2"></p>
                    <div className="auth-login-form mt-2" method="POST">
                      {curStep === 2 ? (
                        <div className="login-user-info-box vhalign">
                          <div className="login-user-info valign">
                            <div className="user-avatar-box">
                              <img
                                className="user-avatar"
                                src="/assets/images/default-avatar.png"
                              />
                            </div>
                            <span className="user-name text-emphasis" title={userData["user_name"]}>
                              {userData["user_name"]}
                            </span>
                            <a
                              className="a-btn btn-user-delete valign"
                              onClick={(e) => {
                                setCurStep(1);
                              }}
                            >
                              <i className="las la-times"></i>
                            </a>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {curStep === 1 ? (
                        <div className="mb-1">
                          <label htmlFor="user_name" className="form-label">
                            Email or phone number
                          </label>
                          <input
                            type="text"
                            id="user_name"
                            name="user_name"
                            className={`form-control form-control-lg ${errorField.includes("user_name")
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder=""
                            tabIndex="1"
                            autoFocus
                            value={get_data_value(userData, "user_name")}
                            onChange={(e) => {
                              onChangeFormField(e, "user_name");
                            }}
                            onKeyDown={(e) => {
                              onChangeKeyInput(e, "user_name");
                            }}
                          />
                        </div>
                      ) : (
                        <div className="mb-1">
                          <label htmlFor="user_password" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            id="user_password"
                            name="user_password"
                            className={`form-control form-control-lg ${errorField.includes("user_password")
                              ? "is-invalid"
                              : ""
                              }`}
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            value={get_data_value(userData, "user_password")}
                            onChange={(e) => {
                              onChangeFormField(e, "user_password");
                            }}
                            onKeyDown={(e) => {
                              onChangeKeyInput(e, "user_password");
                            }}
                          />
                        </div>
                      )}
                      <Ripples
                        className="w-100"
                        color="rgba(255,255,255,0.4)"
                        during={1000}
                      >
                        <button
                          className="btn btn-primary w-100 btn-lg btn-login"
                          tabIndex="4"
                          type="button"
                          onClick={onClickLogin}
                        >
                          {isBusy ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : curStep === 1 ? (
                            "Submit"
                          ) : (
                            "Login"
                          )}
                        </button>
                      </Ripples>
                    </div>

                    {curStep === 2 ? (
                      <p className="text-center mt-2">
                        <Link className="a-btn text-primary" to={ROUTE_FORGOT_PASSWORD}>
                          <span>Forgot Password?</span>
                        </Link>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>

      <MfaCodeModal
        isVisible={showMfaCodeModal}
        setVisibleModal={setShowMfaCodeModal}
        pageData=""
        submitModalData={modalMfaCodeSubmittedCallback}
        modalClass="user-page admin-page confirm-password-modal"
      />

    </div>
  );
};

export default withRouter(LoginPage);
