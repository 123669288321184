import React, {  } from "react";
import { Switch, Route } from "react-router-dom";
import {
  ROUTE_LOGIN,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_PROFILE,
  ROUTE_ADMIN_USER_DETAIL,
  ROUTE_ADMIN_REPORTS,
  ROUTE_ADMIN_COMPLIMENT_MESSAGES,
  ROUTE_ADMIN_COMPLIMENT_ANIMATIONS,
  ROUTE_ADMIN_BANS,

} from "navigation/CONSTANTS";
import HomePage from "pages/Home/HomePage/HomePage";
import LoginPage from "pages/Home/LoginPage/LoginPage";
import ForgotPasswordPage from "pages/Home/ForgotPasswordPage/ForgotPasswordPage";

import AdminUsersPage from "pages/Admin/AdminUsersPage/AdminUsersPage";
import AdminProfilePage from "pages/Admin/AdminProfilePage/AdminProfilePage";
import AdminUserDetailPage from "pages/Admin/AdminUsersPage/AdminUserDetailPage";
import AdminReportsPage from "pages/Admin/AdminReportsPage/AdminReportsPage";
import AdminComplimentMessagePage from "pages/Admin/AdminComplimentPage/AdminComplimentMessagePage";
import AdminComplimentAnimationPage from "pages/Admin/AdminComplimentPage/AdminComplimentAnimationPage";
import AdminBansPage from "pages/Admin/AdminBansPage/AdminBansPage";

export const RouterConfig = () => {
  return (
    <div>
      <Switch>
        <Route exact path={"/"} component={AdminUsersPage} />
        <Route exact path={ROUTE_LOGIN} component={LoginPage} />
        <Route exact path={ROUTE_FORGOT_PASSWORD} component={ForgotPasswordPage} />
      
        <Route exact path={ROUTE_ADMIN_USERS} component={AdminUsersPage} />
        <Route exact path={ROUTE_ADMIN_USER_DETAIL} component={AdminUserDetailPage} />
        <Route exact path={ROUTE_ADMIN_PROFILE} component={AdminProfilePage} />

        <Route exact path={ROUTE_ADMIN_REPORTS} component={AdminReportsPage} />

        <Route exact path={ROUTE_ADMIN_COMPLIMENT_MESSAGES} component={AdminComplimentMessagePage} />
        <Route exact path={ROUTE_ADMIN_COMPLIMENT_ANIMATIONS} component={AdminComplimentAnimationPage} />
        
        <Route exact path={ROUTE_ADMIN_BANS } component={AdminBansPage} />

        {/* <Route exact path="" component={NotFoundPage} /> */}

      </Switch>
    </div>
  );
};




