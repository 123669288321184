import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactCodeInput from "react-code-input";
import { empty, isEmpty, jQuery, showToast } from "utils/GlobalFunctions";

function MfaCodeModal(props) {
  const { isVisible, setVisibleModal, submitModalData, modalClass, onCancelModal } = props;
  const [code, setCode] = useState("");

  const handleClose = () => {
    setVisibleModal(false);
  }

  const submitVerificationCode = () => {
    if (code) {
      submitModalData(code);
    } else {
      showToast("Please input 2-step verification code correctly", "error");
    }
  }

  const onChangeCode = (v) => {
    console.log(`vvvvvvvvvvv:::`, v)
    setCode(v)
  }

  return (
    <>
      <Modal
        show={isVisible}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>2-Step Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block">
            <p className="text-normal margin-bottom-20 text-gray modal-header-desc">
              Enter the 6-digit code generated by your authenticator app.
            </p>
          </div>
          <div className="block margin-bottom-20 text-center">
            <div className="sms-verification-box six-digits-box">
              <div id="dialog">
                <div id="form" className="verification-form">
                  <ReactCodeInput
                    type='number'
                    fields={6}
                    value={code}
                    onChange={onChangeCode}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light btn-cancel-2fa mr-1"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn-complete-2fa btn btn-green btn-primary"
            onClick={submitVerificationCode}
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MfaCodeModal;
