import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  empty,
  get_data_value,
  intval,
  isEmpty,
  jQuery,
  showToast,
} from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";
import { console_log } from "utils/logger";

function AdminTwoAuthenticatorModal(props) {
  const {
    isVisible,
    setVisibleModal,
    pageData,
    submitModalData,
    modalClass,
    modalTitle,
  } = props;
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      setModalPageData(pageData);
      setModalData({ ...defaultModalData });
    }
  }, [isVisible, pageData]);
  const [show, setShow] = useState(false);
  const defaultModalData = {
    password: "",
  };
  const [modalPageData, setModalPageData] = useState({});
  const [modalData, setModalData] = useState(defaultModalData);
  const [errorField, setErrorField] = useState([]);

  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  }

  const onClickSubmitModal = () => {
    submitModalData(true)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-center">
            <div className="modal-box">
              <div id="dialog">
                <div className="text-normal text-left">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="2fa-qr-code-dlg-content padding-10">
                        <div className="block mb-2">
                          <div className="alert alert-warning" role="alert">
                            <div className="note mb-0 p-1 text-center">
                              <p>
                                Use your preferred application from App Store or Google
                                Play and set up an account by scanning the QR code or
                                entering the key.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="block text-center mb-4">
                          <div className="img-wrapper text-center">
                            <img
                              className="img-responsive img-qr-code"
                              style={{
                                width: "180px",
                                height: "180px",
                                background: "#b4b4b4",
                              }}
                              src={get_data_value(pageData, "two_fact_qr_code_url")}
                              alt="QR Code"
                            />
                          </div>
                        </div>
                        <div className="block text-center mb-2">
                          <p className="text-gray">
                            Key: &nbsp;
                            <span
                              className="qr-code-value"
                              style={{ fontSize: "1rem" }}
                            >
                              {get_data_value(pageData, "two_fact_secret")}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-md btn-submit-modal-data btn-primary"
            onClick={onClickSubmitModal}
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminTwoAuthenticatorModal;
