//https://www.twilio.com/blog/build-a-chat-app-with-twilio-programmable-chat-and-react

import { ROUTE_ADMIN_USERS } from "navigation/CONSTANTS";
import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import {
  apiAdminGetTwilioChannel,
  apiAdminGetTwilioToken,
  apiAdminGetUserDetail,
  apiAdminSendMessage,
  apiAdminUpdateUserInfo,
  apiAdminUpdateUserStatus,
} from "services/adminUsersService";
import { empty, isEmpty, showToast, show_loading } from "utils/GlobalFunctions";
import { console_log } from "utils/logger";

import { useHistory } from "react-router-dom";
import { Client as ConversationsClient } from "@twilio/conversations";

const Chat = require("twilio-chat");

function AdminMessageModal(props) {
  const {
    isVisible,
    setVisibleModal,
    pageData,
    currentRow,
    submitModalData,
    modalClass,
    modalTitle,
    reloadReportTable
  } = props;

  const initFormData = {
    message: "",
  };

  const [twilioToken, setTwilioToken] = useState("");
  const [twilioClient, setTwilioClient] = useState(null);
  const [twilioChannel, setTwilioChannel] = useState(null);

  const getTwilioToken = async () => {
    if (twilioToken) {
      return twilioToken;
    } else {
      show_loading(true)
      let api_res = await apiAdminGetTwilioToken({ platform: "web" })
      console_log("getTwilioToken:::", api_res)
      show_loading(false);

      if (api_res && api_res.status == 200) {
        let token = api_res.data.token
        setTwilioToken(token);
        return token;
      } else {
        return false;
      }
    }
  }

  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      if (!empty(currentRow)) {
        setPgageData(currentRow);
        setFormData(initFormData)
        getTwilioToken()
      }
    }
  }, [isVisible, currentRow]);

  useEffect(async () => {
    if (twilioToken !== "") {
      const client = await ConversationsClient.create(twilioToken);
      setTwilioClient(client)

      //await client.getChannelBySid(""); //test
      client.on("tokenAboutToExpire", async () => {
        let token = await getTwilioToken()
        client.updateToken(token);
      });

      client.on("tokenExpired", async () => {
        let token = await getTwilioToken()
        client.updateToken(token);
      });

      client.on('stateChanged', (state) => {
        console_log("client state:::", state)
        if (state === 'initialized') {
          // Use the client
        }
      });

      client.on("conversationJoined", (conversation) => {
        //console_log("conversation:::", conversation)
    //    this.setState({ conversations: [...this.state.conversations, conversation] });
      });
      client.on("conversationLeft", (thisConversation) => {
        //console_log("thisConversation:::", thisConversation)
        // this.setState({
        //   conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
        // });
      });

    }
  }, [twilioToken]);

  const sendTwilioMessage = async (message) => {
    if (currentRow['id']) {
      let channel = null;
      if (false && twilioChannel) {
        channel = twilioChannel
      } else {
        let api_res = await apiAdminGetTwilioChannel({ to_user: currentRow['id'] })
        if (api_res.status == 200) {
          let channelInfo = api_res.data
          console_log("channelInfo:::", channelInfo)
          channel = await twilioClient.getConversationBySid(channelInfo['channel_sid']);
          console_log("channel:::", channel)
          setTwilioChannel(channel)
        }
      }
      let sendResult = channel.sendMessage(String(message).trim());
      console_log("sendResult:::", sendResult)

      let messageData = {report_id: currentRow['report_id'], user_id: currentRow['id'], message: message}
      apiAdminSendMessage(messageData)
      .then((api_res) => {
        if(reloadReportTable) {
          reloadReportTable()
        }
      })
      .catch((err) => {
        
      });
      return true
    } else {
      return false
    }
  }

  const [show, setShow] = useState(false);

  //////////////////////////////////////////////////////////////////

  const initUserData = {};
  const [userData, setUserData] = useState(initUserData);

  const [formData, setFormData] = useState(initFormData);

  const [errorField, setErrorField] = useState([]);

  const setPgageData = (user_info) => {
    setUserData({ ...user_info });
  };

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(formData, "message", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let updateData = { ...formData };
    updateData[field_name] = e.target.value;
    setFormData({ ...updateData });
  };

  const submitData = async () => {
    let is_valid = validateFields();
    //console_log("userData:::", userData)
    if (is_valid) {
      show_loading(true);
      let sendResult = await sendTwilioMessage(formData['message'])
      show_loading(false);

      if (sendResult) {
        showToast("Message has been sent to a user", "success")
        submitModalData()
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-left">
            <div className="modal-box">
              <div id="dialog">
                <div className="admin-user-detail-page">
                  <div className="row">
                    <div className="col-md-12 grid-margin">
                      <div className="">
                        <div className="">
                          <div className="">
                            <div className="row">
                              <div className="col-md-12">
                                <div>
                                  <div className="row">
                                    <div className="col-md-12 col-12">
                                      <div className="mb-1">
                                        <label
                                          className="form-label"
                                          htmlFor="ban_desc"
                                        >
                                          Message
                                        </label>
                                        <textarea className={`form-control message ${errorField.includes("message")
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                          id="message"
                                          name="message"
                                          value={
                                            !empty(formData["message"])
                                              ? formData["message"]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onChangeFormField(e, "message");
                                          }}>
                                          {
                                            !empty(formData["message"])
                                              ? formData["message"]
                                              : ""
                                          }
                                        </textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            <button
              type="button"
              className="mb-50 btn btn-lg1 btn-primary"
              disabled={twilioClient ? false : true}
              onClick={(e) => {
                submitData();
              }}
            >
              Send
            </button>
          }
          <button
            type="button"
            className="btn mb-50 btn-lg1 btn-close-dlg btn-light"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminMessageModal;
