import {
  ADMIN_LEVEL_SUPERADMIN,
  ROUTE_ADMIN_PROFILE,
} from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import { empty, get_data_value } from "utils/GlobalFunctions";
import { console_log } from "utils/logger";

const AdminHeader = (props) => {
  const { askLogout, toggleMUserSideBar } = props;
  const appDataStore = useSelector((x) => x.appDataStore);
  const userDataStore = useSelector((x) => x.userDataStore);

  //////////////////////////socket part////////////////////////////////
  useEffect(() => {}, []);

  const token = get_data_value(userDataStore, "token");
  const socketHeader = { token: token };
  ///////////////////////////end socket part/////////////////////////////

  const dispatch = useDispatch();

  const history = useHistory();
  const onLogout = () => {
    askLogout();
  };

  return (
    <div>
      <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow">
        <div className="navbar-container d-flex content">
          <div className="bookmark-wrapper d-flex align-items-center">
            <ul className="nav navbar-nav d-xl-none">
              <li className="nav-item">
                <a
                  className="nav-link menu-toggle"
                  href="#"
                  onClick={(e) => {
                    toggleMUserSideBar();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-menu ficon"
                  >
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                  </svg>
                </a>
              </li>
            </ul>
            <ul className="nav navbar-nav bookmark-icons">
              <li className="nav-item d-none d-lg-block">
                {appDataStore.currentPageTitle}
              </li>
            </ul>
          </div>
          <ul className="nav navbar-nav align-items-center ms-auto">
            <li className="hidden nav-item dropdown dropdown-notification me-25">
              <a className="nav-link" href="#" data-bs-toggle="dropdown">
                <i className="ficon" data-feather="bell"></i>
                <span className="badge rounded-pill bg-danger badge-up">5</span>
              </a>
              <ul className="dropdown-menu dropdown-menu-media dropdown-menu-end">
                <li className="scrollable-container media-list">
                  <div className="list-item d-flex align-items-center">
                    <h6 className="fw-bolder me-auto mb-0">
                      System Notifications
                    </h6>
                    <div className="form-check form-check-primary form-switch">
                      <input
                        className="form-check-input"
                        id="systemNotification"
                        type="checkbox"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="systemNotification"
                      ></label>
                    </div>
                  </div>

                  <a className="d-flex" href="#">
                    <div className="list-item d-flex align-items-start">
                      <div className="me-1">
                        <div className="avatar bg-light-warning">
                          <div className="avatar-content">
                            <i
                              className="avatar-icon"
                              data-feather="alert-triangle"
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div className="list-item-body flex-grow-1">
                        <p className="media-heading">
                          <span className="fw-bolder">High memory</span>
                          &nbsp;usage
                        </p>
                        <small className="notification-text">
                          {" "}
                          BLR Server using high memory
                        </small>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="dropdown-menu-footer">
                  <a className="btn btn-primary w-100" href="#">
                    Read all notifications
                  </a>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown dropdown-user">
              <a
                className="nav-link dropdown-toggle dropdown-user-link"
                id="dropdown-user"
                href="#"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-nav d-sm-flex">
                  <span className="user-name fw-bolder">
                    {userDataStore["first_name"] +
                      " " +
                      userDataStore["last_name"]}
                  </span>
                  <span className="user-status">Admin</span>
                </div>
                <span className="avatar">
                  <img
                    className="round"
                    src="/assets/images/default-avatar.png"
                    alt="avatar"
                    height="40"
                    width="40"
                  />
                  <span className="avatar-status-online"></span>
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdown-user"
              >
                <Link className="dropdown-item" to={ROUTE_ADMIN_PROFILE}>
                <i className="me-50 las la-cog"></i> Settings
                </Link>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    askLogout();
                  }}
                >
                  <i className="me-50 las la-sign-out-alt"></i> Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default withRouter(AdminHeader);
