import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  empty,
  get_data_value,
  intval,
  isEmpty,
  jQuery,
  showToast,
} from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";

function AdminComplimentMessageModal(props) {
  const {
    isVisible,
    setVisibleModal,
    pageData,
    currentRow,
    submitModalData,
    modalClass,
    modalTitle,
  } = props;
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      setModalPageData(pageData);
      if (!empty(currentRow)) {
        setModalData({ ...defaultModalData, ...currentRow});
      }
    }
  }, [isVisible, pageData, currentRow]);
  const [show, setShow] = useState(false);
  const defaultModalData = {
    message: "",
  };
  const [modalPageData, setModalPageData] = useState({});
  const [modalData, setModalData] = useState(defaultModalData);
  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(modalData, "message", errorList); 
    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let updateData = {};
    updateData[field_name] = e.target.value;
    setModalData({ ...modalData, ...updateData });
  };
  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  const onClickSubmitModal = () => {
    let is_valid = validateFields();
    if (is_valid) {
      submitModalData({ ...modalData, id: currentRow["id"] });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-center">
            <div className="modal-box">
              <div id="dialog">
                <div className="text-normal text-left">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="mb-1">
                        <label className="form-label" htmlFor="message">
                          Message
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder=" "
                          id="message"
                          name="message"
                          className={`form-control message ${
                            errorField.includes("message")
                              ? "is-invalid"
                              : ""
                          }`}
                          value={modalData['message']}
                          onChange={(e) => {
                            onChangeFormField(e, "message");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-md btn-submit-modal-data btn-primary"
            onClick={onClickSubmitModal}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminComplimentMessageModal;
