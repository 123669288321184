import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import {
  ADMIN_LEVEL_ALL,
  ADMIN_LEVEL_ASSISTANT,
  ADMIN_LEVEL_SUPERADMIN,
  USER_LEVEL_AFFILIATE,
  USER_LEVEL_ALL,
  USER_LEVEL_CUSTOMER,
} from "navigation/CONSTANTS";
import { empty, get_data_value } from "utils/GlobalFunctions";
import { ADMIN_MENU_ITEMS } from "./AdminMenuItems";
import { APP_NAME } from "config/CONSTANTS";

const AdminSidebar = (props) => {
  const { askLogout, toggleMUserSideBar } = props;
  const appDataStore = useSelector((x) => x.appDataStore);
  const user = useSelector((x) => x.userDataStore);

  let sideMenuItems = [];
  for (let i = 0; i < ADMIN_MENU_ITEMS.length; i++) {
    let menuItem = ADMIN_MENU_ITEMS[i];
    if (menuItem["level"] === USER_LEVEL_ALL) {
      let menuItemChildRouteList = [];
      if(menuItem['children'].length > 0) {
        for(let k in menuItem['children']) {
          menuItemChildRouteList.push(menuItem['children'][k]['route'])
        }
      }
      menuItem['menuItemChildRouteList'] = menuItemChildRouteList
      sideMenuItems.push(menuItem);
    }
  }

  return (
    <div
      className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
      data-scroll-to-active="true"
    >
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item nav-brand-item">
            <a className="navbar-brand sidebar-brand">
              <img className="brand-logo" src="/assets/images/logo.png" />

              <h2 className="brand-text">{APP_NAME}</h2>
            </a>
          </li>
          <li className="nav-item nav-toggle t-show-mobile">
            <a
              className="nav-link modern-nav-toggle pe-0"
              data-bs-toggle="collapse"
              href="#"
              onClick={(e) => {
                toggleMUserSideBar();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x d-block d-xl-none text-primary toggle-icon font-medium-4"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-disc d-none d-xl-block collapse-toggle-icon font-medium-4 text-primary"
                data-ticon="disc"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            </a>
          </li>
        </ul>
      </div>
      <div className="shadow-bottom"></div>

      <div className="main-menu-content">
        <ul
          className="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
        >
          {sideMenuItems.map((menuItem, index) => (
            <li
              key={index}
              className={`nav-item  ${appDataStore.current_route === menuItem.route ? "active" : ""} ${menuItem["children"].length > 0 ? "has-sub" : ""} ${menuItem["menuItemChildRouteList"].includes(appDataStore.current_route) ? "open" : ""}`}
            >
              {menuItem.route !== "" && (
                <Link to={menuItem.route} className="d-flex align-items-center">
                  <i className={menuItem.icon}></i>
                  <span className="menu-title text-truncate">
                    {menuItem.title}
                  </span>
                </Link>
              )}
              {menuItem.route === "" && (
                <a href="#" className="d-flex align-items-center">
                  <i className={menuItem.icon}></i>
                  <span className="menu-title text-truncate">
                    {menuItem.title}
                  </span>
                </a>
              )}

              {menuItem["children"].length > 0 && (
                <ul className="menu-content">
                  {menuItem["children"].map((menuSubItem, subIndex) => (
                    <li
                      key={index + "_" + subIndex}
                      className={` ${
                        appDataStore.current_route === menuSubItem.route
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        to={menuSubItem.route}
                        className="d-flex align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-circle"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                        <span className="menu-item text-truncate">
                          {menuSubItem.title}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}

          <li className="nav-item">
            <a
              className="d-flex align-items-center"
              href="#"
              onClick={(e) => {
                askLogout();
              }}
            >
              <i className="las la-sign-out-alt"></i>
              <span
                className="menu-title text-truncate"
                data-i18n="Raise Support"
              >
                Logout
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminSidebar;
