import { APP_NAME, SYSTEM_ERROR } from "config/CONSTANTS";
import { curl_post } from "utils/GlobalFunctions";
import { axiosGet, axiosPost, axiosPostMultipart } from "./ajaxServices";
import {urlAdminDeleteComplimentAnimation, urlAdminDeleteComplimentMessage, urlAdminDeleteUser, urlAdminDownloadUserList, urlAdminGetComplimentAnimationList, urlAdminGetComplimentMessageList, urlAdminGetUserDetail, urlAdminGetUsersPageDetail, urlAdminSetUserTmpPassword, urlAdminUpdateComplimentAnimation, urlAdminUpdateComplimentMessage, urlAdminUpdateUserInfo, urlAdminUpdateUserStatus, urlUserDeletePaymentRow, urlUserGetPaymentListPageDetail } from "./CONSTANTS";

export function apiAdminGetComplimentMessageList(params) {
  const url = urlAdminGetComplimentMessageList ;
  return new Promise((resolve, reject) => {
    axiosGet(url, params)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 
 
export function apiAdminUpdateComplimentMessage(params) {
  const url = urlAdminUpdateComplimentMessage;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", params.id);
  formData.append("message", params.message);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 

export function apiAdminDeleteComplimentMessage(params) {
  const url = urlAdminDeleteComplimentMessage;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", params.id);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 

export function apiAdminGetComplimentAnimationList(params) {
  const url = urlAdminGetComplimentAnimationList;
  return new Promise((resolve, reject) => {
    axiosGet(url, params)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
}

export function apiAdminUpdateComplimentAnimation(params) {
  const url = urlAdminUpdateComplimentAnimation;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", params.id);
  formData.append("name", params.name);
  formData.append("upload_file", params.uploadFile);
  formData.append("upload_animate_file", params.uploadAnimateFile);

  return new Promise((resolve, reject) => {
    axiosPostMultipart(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 

export function apiAdminDeleteComplimentAnimation(params) {
  const url = urlAdminDeleteComplimentAnimation;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", params.id);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 