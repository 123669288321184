import React, { useState } from "react";
import { Input } from 'reactstrap';
import "./filter.css"

export function ageBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? filterValues[0] : 0;
  const ed = filterValues[1] ? filterValues[1] : 200;
  if (ed || sd) {
    return rows.filter((r) => {
      let celldata = r.values[id];
      console.log("@@ed", ed)
      console.log("@@sd", sd)
      if (ed && sd) {
        return celldata >= sd && celldata <= ed;
      } else if (sd) {
        return celldata >= sd;
      } else {
        return celldata <= ed;
      }
    });
  } else {
    return rows;
  }
}

export function AgeRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
  filterObject,
  onFilterKeyChange
}) {
  const [minVal, setMinVal] = useState(filterObject[id]?.split(',')[0] ?? '');
  const [maxVal, setMaxVal] = useState(filterObject[id]?.split(',')[1] ?? '');

  return (
    <div className="d-flex">
      <Input
        placeholder="Min"
        type="number"
        style={{ width: 50, marginRight: 5, padding: 5 }}
        value={minVal}
        onKeyDown={(e) => {
          if (e.key == 'Enter') {
            onFilterKeyChange(id, `${minVal},${maxVal}`)
          }
        }}
        onChange={(e) => {
          setMinVal(e.target.value);
          if (e.target.value == "") {
            onFilterKeyChange(id, `,${maxVal}`)
          }
        }}
      />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{"-"}</div>
      <Input
        placeholder="Max"
        type="number"
        style={{ width: 50, marginLeft: 5, padding: 5 }}
        value={maxVal}
        onKeyDown={(e) => {
          if (e.key == 'Enter') {
            onFilterKeyChange(id, `${minVal},${maxVal}`)
          }
        }}
        onChange={(e) => {
          setMaxVal(e.target.value);
          if (e.target.value == "") {
            onFilterKeyChange(id, `${minVal},`)
          }
        }}
      />
    </div>
  );
}
