import { ROUTE_ADMIN_USERS } from "navigation/CONSTANTS";
import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import {
  apiAdminGetUserDetail,
  apiAdminUpdateUserInfo,
  apiAdminUpdateUserStatus,
} from "services/adminUsersService";
import { empty, isEmpty, showToast, show_loading } from "utils/GlobalFunctions";
import { console_log } from "utils/logger";

import { useHistory } from "react-router-dom";

function AdminBanReasonModal(props) {
  const {
    isVisible,
    setVisibleModal,
    pageData,
    currentRow,
    submitModalData,
    modalClass,
    modalTitle,
  } = props;

  const initFormData = {
    ban_reason: "",
    ban_desc: ""
  };

  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      if (!empty(currentRow)) {
        setPgageData(currentRow);
      }
      setFormData(initFormData)
    }
  }, [isVisible, currentRow]);
  const [show, setShow] = useState(false);

  //////////////////////////////////////////////////////////////////

  const initUserData = {};
  const [userData, setUserData] = useState(initUserData);

  const [formData, setFormData] = useState(initFormData);

  const [errorField, setErrorField] = useState([]);

  const setPgageData = (user_info) => {
    setUserData({ ...user_info });
  };

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(formData, "ban_reason", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let updateData = { ...formData };
    updateData[field_name] = e.target.value;
    setFormData({ ...updateData });
  };

  const submitData = () => {
    let is_valid = validateFields();
    //console_log("userData:::", userData)
    if (is_valid) {
      show_loading(true);
      apiAdminUpdateUserStatus({ id: userData['id'], user_status:"deactivate", ...formData })
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          if (api_res.status == 200) {
            let user_info = api_res["data"]["user"];
            setPgageData(user_info);
            showToast(api_res.message, "success");
            submitModalData(user_info);
            handleClose()
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  };

  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-left">
            <div className="modal-box">
              <div id="dialog">
                <div className="admin-user-detail-page">
                  <div className="row">
                    <div className="col-md-12 grid-margin">
                      <div className="">
                        <div className="">
                          <div className="">
                            <div className="row">
                              <div className="col-md-12">
                                <div>
                                  <div className="row">
                                    <div className="col-md-12 col-12">
                                      <div className="mb-1">
                                        <label
                                          className="form-label"
                                          htmlFor="ban_reason"
                                        >
                                          Reason
                                        </label>
                                        <input
                                          type="text"
                                          className={`form-control ban_reason ${errorField.includes("ban_reason")
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          id="ban_reason"
                                          name="ban_reason"
                                          value={
                                            !empty(formData["ban_reason"])
                                              ? formData["ban_reason"]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onChangeFormField(e, "ban_reason");
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                      <div className="mb-1">
                                        <label
                                          className="form-label"
                                          htmlFor="ban_desc"
                                        >
                                          Description
                                        </label>
                                        <textarea className={`form-control ban_desc ${errorField.includes("ban_desc")
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                          id="ban_desc"
                                          name="ban_desc"
                                          value={
                                            !empty(formData["ban_desc"])
                                              ? formData["ban_desc"]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onChangeFormField(e, "ban_desc");
                                          }}>
                                          {
                                            !empty(formData["ban_desc"])
                                              ? formData["ban_desc"]
                                              : ""
                                          }
                                        </textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="mb-50 btn btn-lg1 btn-primary"
            onClick={(e) => {
              submitData();
            }}
          >
            Submit
          </button>
          <button
            type="button"
            className="btn mb-50 btn-lg1 btn-close-dlg btn-light"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminBanReasonModal;
