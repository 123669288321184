import React, { useState } from 'react';
import { Input, CustomInput } from 'reactstrap';

export const Filter = ({ column }) => {
  return (
    // <div style={{ marginTop: 5,width:column.render('Header') === 'Gender' ? column.render('width') : 'auto' }}>
    <div style={{ width: column.render('width') }}>
      {column.canFilter && column.render('Filter')}
    </div>
  );
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows,
    id
  },
  filterObject,
  onFilterKeyChange
}) => {
  const [changeVal, setChangeVal] = useState(filterObject[id]);
  return (
    <Input
      type="search"
      placeholder="Search"
      key={id}
      style={{ width: "100%", padding: 5 }}
      value={changeVal}
      onKeyDown={(e) => {
        if (e.key == 'Enter') {
          onFilterKeyChange(id, changeVal)
        }
      }}
      onChange={(e) => {
        setChangeVal(e.target.value);
        if (e.target.value == "") {
          onFilterKeyChange(id, "")
        }
      }}
    />
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  filterObject,
  onFilterKeyChange
}) => {
  return (
    <CustomInput
      id='custom-select'
      type='select'
      className="gender-select"
      value={filterObject[id]}
      onChange={(e) => {
        onFilterKeyChange(id, e.target.value);
      }}
    >
      <option value=''>All</option>
      <option value={1}>Male</option>
      <option value={2}>Female</option>
    </CustomInput>
  );
};
