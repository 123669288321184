import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import React, { useState, useEffect, useCallback, useMemo, Fragment } from "react";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import axios from "axios";
import { DateRangeColumnFilter, dateBetweenFilterFn } from "../../../../utils/filterDate";
import { AgeRangeColumnFilter, ageBetweenFilterFn } from "../../../../utils/filterAge";


import {
  curl_post,
  intval,
  showToast,
  show_loading,
  objectToUrlParams
} from "utils/GlobalFunctions";
import {
  urlAdminDeleteUser,
  urlAdminDownloadUserList,
  urlAdminGetFilteredUsers,
  urlAdminGetUsersList,
} from "services/CONSTANTS";
import AdminUserDownloadModal from "./AdminUserDownloadModal";

import {
  apiAdminDeleteUser,
  apiAdminGetUserDetail,
  apiAdminUpdateUserInfo,
} from "services/adminUsersService";
import AdminUserInfoModal from "./AdminUserInfoModal";
import { console_log } from "utils/logger";
import { useHistory } from "react-router-dom";
import AdminUserEditModal from "./AdminUserEditModal";

///////////////////////////////////////////
import {
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap';
import TableContainer from '../../../../utils/TableContainer';
import { useRef } from "react";
import { SelectColumnFilter } from "utils/filters";
///////////////////////////////////////////

const AdminUserListTable = (props) => {
  const userDataStore = useSelector((x) => x.userDataStore);
  const { pageData } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInput, setPageInput] = useState(currentPage);
  // const [deleted, setDeleted] = useState([]);
  const [currentSortField, setCurrentSortField] = useState("created_at");
  const [currentSortDirection, setCurrentSortDirection] = useState("desc");

  const [searchText, setSearchText] = useState("");
  const filterObject = useRef({});

  const fetchDataList = async (
    page,
    size = perPage,
    keyword = searchText,
    sortColumn = currentSortField,
    sortDirection = currentSortDirection
  ) => {
    show_loading(true);

    const response = await axios.get(
      `${urlAdminGetUsersList}?page=${page}&per_page=${size}&keyword1=${keyword}&sort_column=${sortColumn}&sort_direction=${sortDirection}`
    );

    setData(response.data.data);
    setTotalRows(response.data.total);
    show_loading(false);
  };

  useEffect(() => {
    filterObject.current = initFilterData();
    fetchDataList(1);
  }, []);

  const history = useHistory();

  const removeItem = (array, item) => {
    const newArray = array.slice();
    newArray.splice(
      newArray.findIndex((a) => a === item),
      1
    );
    return newArray;
  };

  const handleDelete = useCallback(
    (row) => async () => {
      await axios.delete(`${urlAdminDeleteUser}?id=${row.id}`);
      const response = await axios.get(
        `${urlAdminGetUsersList}?page=${currentPage}&per_page=${perPage}&keyword1=${searchText}&sort_column=${currentSortField}&sort_direction=${currentSortDirection}`
      );

      setData(removeItem(response.data.data, row));
      setTotalRows(totalRows - 1);
    },
    [currentPage, perPage, totalRows]
  );
  const handlePageChange = (page) => {
    fetchDataListWithFilter(page);
    setCurrentPage(page);
    setPageInput(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    fetchDataListWithFilter(page, newPerPage);
    setPerPage(newPerPage);
  };
  const handleSearchChange = (searchKey = searchText) => {
    if (searchKey) {
      filterObject.current = initFilterData();
    }
    let curPage = 1; //currentPage
    setCurrentPage(curPage);
    fetchDataList(curPage, perPage, searchKey);
    //setSearchText(newSearchText);
  };

  const handleSortChange = (columnId, isSortedDesc) => {
    //let sortColumn = column["id"];
    setCurrentSortField(columnId);
    let sortDirection = isSortedDesc ? "desc" : "asc";
    console.log("---sort---", columnId, isSortedDesc);
    setCurrentSortDirection(sortDirection);
    fetchDataListWithFilter(currentPage, perPage, columnId, sortDirection);
  };

  const fetchDataListWithFilter = async (
    page,
    size = perPage,
    sortColumn = currentSortField,
    sortDirection = currentSortDirection
  ) => {
    show_loading(true);
    const filterParams = objectToUrlParams(filterObject.current);
    const response = await axios.get(
      `${urlAdminGetFilteredUsers}?page=${page}&per_page=${size}&${filterParams}&sort_column=${sortColumn}&sort_direction=${sortDirection}`
    );
    setData(response.data.data);
    setTotalRows(response.data.total);
    show_loading(false);
  };

  const initFilterData = () => {
    const filterData = {}
    for (let k in columns) {
      if (columns[k].accessor) {
        if (columns[k].accessor === 'age' || columns[k].accessor === 'created_at') {
          filterData[columns[k].accessor] = ","
        } else {
          filterData[columns[k].accessor] = ""
        }
      }
    }
    return filterData
  };

  const handleFilterUpdate = (filterKey, filterValue) => {
    if (filterValue) {
      setSearchText("")
    }
    handleSetTextObject(filterKey, filterValue);
    console.log("filterObject->", filterObject.current);
    let curPage = 1; //currentPage
    setCurrentPage(curPage);
    fetchDataListWithFilter(curPage);
  };

  const handleSetTextObject = (id, val) => {
    const updateData = {
      ...filterObject.current
    }
    updateData[id] = val;
    filterObject.current = updateData
  }

  const renderRowSubComponent = (row) => {
    const {
      name: { first, last },
      location: { city, street, postcode },
      picture,
      cell,
    } = row.original;
    return (
      <Card style={{ width: '18rem', margin: '0 auto' }}>
        <CardImg top src={picture.large} alt='Card image cap' />
        <CardBody>
          <CardTitle>
            <strong>{`${first} ${last}`} </strong>
          </CardTitle>
          <CardText>
            <strong>Phone</strong>: {cell} <br />
            <strong>Address:</strong>{' '}
            {`${street.name} ${street.number} - ${postcode} - ${city}`}
          </CardText>
        </CardBody>
      </Card>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        width: "80%",
        minWidth: "180px",
        maxWidth: "100%",
        sortable: true,
        Cell: (row) => (
          <div
            className="td-user-avatar-name-wrapper"
            onClick={(e) => {
              onClickEditItem(row.row.original);
            }}
          >
            <div>
              <div className="td-user-avatar"
                style={{ alignSelf: 'center', flex: 1, backgroundImage: "url('" + row.row.original.user_photo_url + "')" }}
              >
              </div>
            </div>
            <div style={{ padding: "0px 30px", wordBreak: "break-word" }}>
              {row.row.original.name ?? ""}
            </div>
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        width: "80%",
        minWidth: "160px",
        maxWidth: "100%",
        sortable: true,
        Cell: (row) => (
          <div style={{ width: "80%", wordBreak: "break-word" }}
            onClick={(e) => {
              onClickEditItem(row.row.original);
            }}
          >
            {row.row.original.email}
          </div>
        ),
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
        sortable: true,
        width: "80%",
        minWidth: "120px",
        maxWidth: "100%",
        Cell: (row) => (
          <div style={{ width: "80%" }}
            className="td-wrapper"
            onClick={(e) => {
              onClickEditItem(row.row.original);
            }}
          >
            {row.row.original.phone_number}
          </div>
        ),
      },
      {
        Header: "Age",
        accessor: "age",
        sortable: true,
        width: "80%",
        minWidth: "160px",
        maxWidth: "100%",
        Filter: (param) => AgeRangeColumnFilter({ ...param, filterObject: filterObject.current, onFilterKeyChange: handleFilterUpdate }),
        //filter: ageBetweenFilterFn,
        Cell: (row) => (
          <div style={{ width: "80%", paddingRight: "50px" }}
            className="td-wrapper"
            onClick={(e) => {
              onClickEditItem(row.row.original);
            }}
          >
            {row.row.original.age}
          </div>
        ),
      },
      {
        Header: "Gender",
        accessor: "gender_id",
        sortable: true,
        width: "100%",
        minWidth: "160px",
        maxWidth: "100%",
        Filter: (param) => SelectColumnFilter({ ...param, filterObject: filterObject.current, onFilterKeyChange: handleFilterUpdate }),
        Cell: (row) => (
          <div className="td-wrapper" style={{ width: "80%" }}>
            <span
              onClick={(e) => {
                onClickEditItem(row.row.original);
              }}
            >
              {row.row.original.gender_id === 1 ? "Male" : (row.row.original.gender_id === 2 ? "Female" : "")}
            </span></div>
        ),
      },
      // {
      //   Header: "Looking for",
      //   accessor: "ug_gender_id",
      //   sortable: true,
      //   cell: (row) => <span>{(row.ug_gender_id)}</span>,
      // },
      {
        Header: "Location",
        accessor: "location",
        sortable: true,
        width: "80%",
        minWidth: "120px",
        maxWidth: "100%",
        Cell: (row) => (
          <div style={{ width: "80%", wordBreak: "break-word" }}
            className="td-wrapper"
            onClick={(e) => {
              onClickEditItem(row.row.original);
            }}
          >
            {row.row.original.location}
          </div>
        ),
      },
      {
        Header: "Added Date",
        accessor: "created_at",
        Filter: (param) => DateRangeColumnFilter({ ...param, filterObject: filterObject.current, onFilterKeyChange: handleFilterUpdate }),
        //filter: dateBetweenFilterFn,
        sortable: true,
        isSorted: true,
        width: "160px",
        minWidth: "120px",
        maxWidth: "100%",
        Cell: (row) => (
          <div style={{ width: "80%" }}>
            <span
              onClick={(e) => {
                onClickEditItem(row.row.original);
              }}
            >
              {row.row.original.created_at.substr(0, 10)}
            </span>
          </div>
        ),
      },
      {
        Header: "Action",
        width: "150px",
        minWidth: "120px",
        // eslint-disable-next-line react/button-has-type
        Cell: (row) => (
          <div>
            <button
              type="button"
              className="btn btn-icon td-btn btn-info"
              title="View Detail"
              onClick={() => onClickEditItem(row.row.original)}
            >
              <i className="las la-eye"></i>
            </button>
            <button
              type="button"
              className="btn btn-icon td-btn btn-primary"
              title="Edit"
              onClick={() => onClickEditUser(row.row.original)}
            >
              <i className="las la-pencil-alt"></i>
            </button>
            <button
              type="button"
              className="btn btn-icon td-btn btn-danger"
              title="Delete"
              onClick={() => onClickDeleteUser(row.row.original)}
            >
              <i className="las la-trash"></i>
            </button>
          </div>
        ),
      },
    ],
    [handleDelete]
  );

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleClear = () => {
    //setSearchText("")
    filterObject.current = initFilterData();
    setResetPaginationToggle(!resetPaginationToggle);
    handleSearchChange("");
  };
  const subHeaderComponent = () => {

    return (
      <div className="datatable-subheader">
        <div className="row">
          <div className="col-md-12">
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <div
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left'
                }}>
                {`Total Users: ${totalRows}`}
              </div>
              <div style={{ flex: 1 }}>
                <div
                  className="pull-right"
                  style={{ border: "1px solid transparent", marginLeft: "4px" }}
                >
                  <button
                    type="button"
                    className="btn btn-icon btn-primary"
                    title="Download"
                    onClick={(e) => {
                      onClickDownload();
                    }}
                  >
                    <i className="las la-download"></i>
                  </button>
                </div>
                <div className="datatable-filter-box pull-right">
                  <input
                    id="search"
                    type="search"
                    placeholder="Search..."
                    value={searchText}
                    className="form-control input-search"
                    //onChange={(e) => handleSearchChange(e.target.value)}
                    onChange={(e) => {
                      setSearchText(e.target.value)
                      if (e.target.value == "") {
                        handleClear()
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == 'Enter') {
                        handleSearchChange()
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //////////////////////////////////////////start modal actions///////////////////////////////////////////////
  const [currentRow, setCurrentRow] = useState({});

  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [showUserEditModal, setShowUserEditModal] = useState(false);

  const onRowsClicked = (row, event) => {
    onClickEditItem(row);
  };
  const onClickEditItem = (row) => {
    let params = {
      user_id: row["id"],
    };
    show_loading(true);
    apiAdminGetUserDetail(params)
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          let user_info = api_res["data"]["user"];
          setCurrentRow(user_info);
          setShowUserInfoModal(true);
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };

  const onClickEditUser = (row) => {
    let params = {
      user_id: row["id"],
    };
    show_loading(true);
    apiAdminGetUserDetail(params)
      .then((api_res) => {
        console_log("api_res", api_res);
        show_loading(false);
        if (api_res.status == 200) {
          let user_info = api_res["data"]["user"];
          setCurrentRow(user_info);
          setShowUserEditModal(true);
        }
      })
      .catch((err) => {
        //setIsBusy(false);
        show_loading(false);
        showToast(err, "error");
      });
  };
  const goItemDetail = (row) => {
    history.push("/admin/user/detail/" + row["id"]);
  }
  const updateUserInfo = (params) => {
    show_loading(true);
    apiAdminUpdateUserInfo(params)
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setShowUserInfoModal(false);
          showToast("User has been updated successfully", "success");

          fetchDataListWithFilter(currentPage);
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  const onClickDeleteUser = (row) => {
    setCurrentRow(row);
    setConfirmModalTitle("Are you sure you want to delete?");
    setConfirmedAction("delete_user");
    setShowConfirmModal(true);
  };

  const [confirmModalTitle, setConfirmModalTitle] = useState(
    "Are you sure you want to delete?"
  );
  const [confirmedAction, setConfirmedAction] = useState("change_user_status");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const doConfirmedAction = () => {
    let params = {};
    if (confirmedAction === "delete_user") {
      params = {
        user_id: currentRow["id"],
      };
      show_loading(true);
      apiAdminDeleteUser(params)
        .then((api_res) => {
          console_log("api_res", api_res);
          show_loading(false);
          setShowConfirmModal(false);
          if (api_res.status == 200) {
            fetchDataListWithFilter(currentPage);
            showToast(api_res["message"], "success");
          }
        })
        .catch((err) => {
          //setIsBusy(false);
          show_loading(false);
          showToast(err, "error");
        });
    }
  };

  const onReloadDataTable = (dlgClose = "") => {
    if (dlgClose == "AdminUserInfoModal") {
      setShowUserInfoModal(false);
    }
    fetchDataListWithFilter(currentPage);
  }

  const [showUserDownloadModal, setShowUserDownloadModal] = useState(false);
  const onClickDownload = () => {
    setShowUserDownloadModal(true);
  };
  const doDownloadUserList = (option) => {
    setShowUserDownloadModal(false);
    let pageRange = option["pageRange"];
    let page = 0;
    if (pageRange == "current_page") {
      page = currentPage;
    }
    let post_param = {
      pageRange: pageRange,
      pageColumnList: JSON.stringify(option["pageColumnList"]),
      page: page,
      per_page: perPage,
      keyword1: searchText,
      sort_column: currentSortField,
      sort_direction: currentSortDirection,
      token: userDataStore["token"],
    };
    curl_post(urlAdminDownloadUserList, post_param, "post");
  };

  const handlePagination = (page) => {
    handlePageChange(page.selected + 1);
  };
  const handlePageSize = (event) => {
    let page_size = event.target.value;
    console.log("page_size", page_size);
    let cur_page = 1; // currentPage
    setCurrentPage(cur_page);
    handlePerRowsChange(intval(page_size), cur_page);
  };
  // ** Custom Pagination
  const CustomPagination = () => {
    const count = Math.ceil(totalRows / perPage);
    //const [pageInput, setPageInput] = useState(currentPage);
    const handlePageInput = (event) => {
      let page_number = event.target.value;
      console.log("page_number", page_number);
      setPageInput(intval(page_number));
    };
    const submitPageInput = () => {
      if (pageInput > 0 && pageInput <= Math.ceil(totalRows / perPage)) {
        handlePageChange(pageInput);
      } else {
        showToast("Invalid page number", "error");
      }
    };

    const onPageChangeKeyInput = (e) => {
      if (e.key === "Enter") {
        submitPageInput();
      }
    };

    return (
      <div className="datatable-footer-wrapper">
        <div className="page-size-select-box vhalign">
          <span>Rows per page:&nbsp;</span>
          <select
            className="page-size-select"
            aria-label="Rows per page:"
            onChange={(e) => {
              handlePageSize(e);
            }}
            value={perPage}
          >
            {[5, 10, 25, 50, 100].map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <ReactPaginate
          previousLabel={""}
          nextLabel={""}
          breakLabel="..."
          pageCount={Math.ceil(count) || 1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          activeClassName="active"
          forcePage={currentPage !== 0 ? currentPage - 1 : 0}
          onPageChange={(page) => handlePagination(page)}
          pageClassName="page-item"
          breakClassName="page-item"
          nextLinkClassName="page-link"
          pageLinkClassName="page-link"
          breakLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextClassName="page-item next-item"
          previousClassName="page-item prev-item"
          containerClassName={"pagination"}
        />
        <div className="page-number-input-box vhalign">
          <span>Go to page:&nbsp;</span>
          <input
            type="number"
            placeholder=""
            min="0"
            value={pageInput}
            className="page-number-input"
            onChange={(e) => handlePageInput(e)}
            onKeyDown={(e) => {
              onPageChangeKeyInput(e);
            }}
          />
          <button
            type="button"
            className="btn btn-primary btn-sm btn-submit-page-number"
            onClick={(e) => {
              submitPageInput();
            }}
          >
            Go
          </button>
        </div>
      </div>
    );
  };

  ////////////////////////////////////////////end modal actions/////////////////////////////////////////////////////////

  return (
    <>
      <div className="datatable-wrapper">

        <Container style={{ marginTop: 20, width: "100%" }}>
          <TableContainer
            columns={columns}
            data={data}
            subHeaderComponent={subHeaderComponent}
            renderRowSubComponent={renderRowSubComponent}
            paginationComponent={CustomPagination}
            onFilterKeyChange={handleFilterUpdate}
            onSortChange={handleSortChange}
            filterObject={filterObject.current}
            perPageSize={perPage}
          />
        </Container>
      </div>

      <AdminUserInfoModal
        modalTitle={`User detail`}
        isVisible={showUserInfoModal}
        setVisibleModal={setShowUserInfoModal}
        pageData={pageData}
        currentRow={currentRow}
        submitModalData={updateUserInfo}
        onReloadTable={onReloadDataTable}
        modalClass="user-page admin-page user-info-modal modal-lg"
      />

      <AdminUserEditModal
        modalTitle={`Edit User`}
        isVisible={showUserEditModal}
        setVisibleModal={setShowUserEditModal}
        pageData={pageData}
        currentRow={currentRow}
        submitModalData={(e) => { fetchDataListWithFilter(currentPage) }}
        modalClass="user-page admin-page user-info-modal modal-lg"
      />

      <AdminUserDownloadModal
        modalTitle={`Choose download option`}
        isVisible={showUserDownloadModal}
        setVisibleModal={setShowUserDownloadModal}
        pageData={pageData}
        submitModalData={doDownloadUserList}
        modalClass="user-page admin-page user-info-modal"
      />

      <ConfirmModal
        content={confirmModalTitle}
        onClickYes={doConfirmedAction}
        visibleModal={showConfirmModal}
        setVisibleModal={setShowConfirmModal}
        modalClass="user-page admin-page confirm-modal"
      />
    </>
  );
};

export default AdminUserListTable;
