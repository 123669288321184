import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  empty,
  get_data_value,
  intval,
  isEmpty,
  jQuery,
  showToast,
} from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import AdminBanReasonModal from "pages/Admin/AdminUsersPage/inc/AdminBanReasonModal";
import { console_log } from "utils/logger";
import AdminMessageModal from "pages/Admin/AdminUsersPage/inc/AdminMessageModal";

function AdminReportInfoModal(props) {
  const {
    isVisible,
    setVisibleModal,
    pageData,
    currentRow,
    modalClass,
    modalTitle,
    suspendUser,
    updateReportStatus,
    deleteItem,
    closeItem,
    reloadReportTable,
    currentReportedUser
  } = props;
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      setModalPageData(pageData);
      if (!empty(currentRow)) {
        setModalData({ ...defaultModalData, ...currentRow });
        if (currentRow["screenshots"] && currentRow["screenshots"].length > 0) {
          setImages([...currentRow["screenshots"]]);
        } else {
          setImages([]);
        }
      }
    }
  }, [isVisible]);
  const [show, setShow] = useState(false);
  const defaultModalData = {
    created_at: "",
  };
  const [modalPageData, setModalPageData] = useState({});
  const [modalData, setModalData] = useState(defaultModalData);
  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(modalData, "user_email", errorList);
    errorList = isEmpty(modalData, "user_first_name", errorList);
    errorList = isEmpty(modalData, "user_last_name", errorList);
    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let updateData = {};
    updateData[field_name] = e.target.value;
    setModalData({ ...modalData, ...updateData });
  };
  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  const onClickSuspendUser = () => {
    console_log("modalData:::", modalData)
    setShowUserBanModal(true);
  };
  const onClickDeleteItem = () => {
    deleteItem(modalData)
  };

  const onClickCloseReport = () => {
    closeItem(modalData)
  }

  const [images, setImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const showPhotoLightBox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const [showUserBanModal, setShowUserBanModal] = useState(false);
  const onSubmitBanModalData = (row) => {
    let newModalData = { ...modalData, status: "Banned" }
    setModalData(newModalData)
    suspendUser(newModalData)
  }

  const [showAdminMessageModal, setShowAdminMessageModal] = useState(false);
  const onClickMessageUser = () => {
    setShowAdminMessageModal(true);
  };
  const onSubmitMessageModalData = () => {
    let newModalData = { ...modalData, status: "Messaged" }
    setModalData(newModalData)
    updateReportStatus(newModalData)
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-center">
            <div className="modal-box">
              <div id="dialog">
                <div className="report-detail-box text-left">
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-6">
                      <div className="mb-1">
                        <label className="form-label">
                          Reported by: &nbsp;
                        </label>
                        {modalData["report_user_name"]}
                      </div>
                      <div className="mb-1">
                        <label className="form-label">
                          Reported User: &nbsp;
                        </label>
                        {modalData["reported_user_name"]}
                      </div>
                      <div className="mb-1">
                        <label className="form-label">Reason: &nbsp;</label>
                        <span>{modalData["reason"]}</span>
                      </div>
                      <div className="mb-1">
                        <label className="form-label">Description:</label>
                        <br />
                        <span>{modalData["description"]}</span>
                      </div>
                      <div className="mb-1">
                        <label className="form-label">
                          Reported Date: &nbsp;
                        </label>
                        <span>{modalData['created_at'].substr(0, 10)}</span>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-6">
                      <div className="user-screenshot-carousel simple-carousel">
                        {images.length > 0 && (
                          <Carousel
                            autoPlay={true}
                            interval={3000}
                            infiniteLoop={true}
                            swipeable={true}
                          >
                            {images.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={(e) => {
                                    showPhotoLightBox(index);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={item} />
                                  {/* <p className="legend"></p> */}
                                </div>
                              );
                            })}
                          </Carousel>
                        )}
                      </div>
                      {isOpen && (
                        <Lightbox
                          mainSrc={images[photoIndex]}
                          nextSrc={images[(photoIndex + 1) % images.length]}
                          prevSrc={
                            images[
                            (photoIndex + images.length - 1) % images.length
                            ]
                          }
                          onCloseRequest={() => setIsOpen(false)}
                          onMovePrevRequest={() =>
                            setPhotoIndex(
                              (photoIndex + images.length - 1) % images.length
                            )
                          }
                          onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                          }
                        />
                      )}
                      {images.length === 0 && (
                        <div className="vhalign h-100">No screenshot uploaded</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            (currentReportedUser['deactivated'] == 0) && (
              <>
                <button
                  type="button"
                  className="btn btn-md btn-submit-modal-data btn-warning"
                  onClick={onClickSuspendUser}
                >
                  Ban User
                </button>

              </>
            )
          }
          <button
            type="button"
            className="btn btn-md btn-info"
            data-dismiss="modal"
            onClick={(e) => { onClickMessageUser() }}
          >
            Message
          </button>
          {
            (modalData['status'] !== 'Closed') && (
              <>
                <button
                  type="button"
                  className="btn btn-md btn-dark"
                  data-dismiss="modal"
                  onClick={(e) => { onClickCloseReport() }}
                >
                  Close Report
                </button>
              </>
            )
          }

          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light"
            data-dismiss="modal"
            onClick={(e) => { handleClose() }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <AdminBanReasonModal
        modalTitle={`Ban User`}
        isVisible={showUserBanModal}
        setVisibleModal={setShowUserBanModal}
        pageData={pageData}
        currentRow={{ id: modalData['reported_user_id'] }}
        submitModalData={(row) => { onSubmitBanModalData(row) }}
        modalClass="user-page admin-page user-ban-modal"
      />

      <AdminMessageModal
        modalTitle={`Send a message`}
        isVisible={showAdminMessageModal}
        setVisibleModal={setShowAdminMessageModal}
        pageData={pageData}
        currentRow={{ id: modalData['reported_user_id'], report_id: modalData['id'] }}
        submitModalData={() => {
          setShowAdminMessageModal(false)
          onSubmitMessageModalData()
        }}
        modalClass="user-page admin-page user-ban-modal"
        reloadReportTable={reloadReportTable}
      />
    </>
  );
}

export default AdminReportInfoModal;
