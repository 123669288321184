import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_ADMIN_DASHBOARD,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_USER_DASHBOARD,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import TwoFactorCodeModal from "components/TwoFactorCodeModal/TwoFactorCodeModal";
import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
} from "utils/GlobalFunctions";
import {
  console_log,
} from "utils/logger";
import {
  apiLogin,
  apiLoginTwoFactAuth,
  apiLogout,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";

const LoginPage = (props) => {
  return (
    <div>
      <HomeLayout>
        <div className="main-content">
          <div
            className="section page-section container has-header content-sm"
            id="page-section1"
          >         
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default withRouter(LoginPage);
