import React, { useEffect, useState } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app


function AdminUserPhotoBox(props) {
  const {
    isVisible,
    photoList,
    boxClass
  } = props;
  useEffect(() => {
    if (isVisible !== undefined) {
      setImages(photoList)
    }
  }, [isVisible, photoList]);

  const [images, setImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const showPhotoLightBox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <>
      <div className={`carousel-lightbox-wrapper ${images.length === 0 ? "hidden" : ""}`}>
        <div className={`user-avatar-carousel ${boxClass}`}>
          {images.length > 0 && (
            <Carousel
              autoPlay={true}
              interval={3000}
              infiniteLoop={true}
              swipeable={true}
            >
              {images.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={(e) => {
                      showPhotoLightBox(index);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={item} />
                    {/* <p className="legend"></p> */}
                  </div>
                );
              })}
            </Carousel>
          )}
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </div>
    </>
  );
}

export default AdminUserPhotoBox;
